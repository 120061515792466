import { useLocation } from '@reach/router';

// activeClassName on Gatsby/Reach's Link matches /thing when on /thing-other which we don't want
// so we use this instead
export function useIsPathnameActive(
  pathname: string,
  matchChildRoutes: boolean = true
) {
  const location = useLocation();
  return (
    location.pathname === pathname ||
    (matchChildRoutes
      ? location.pathname.startsWith(`${pathname}/`)
      : location.pathname === `${pathname}/`)
  );
}
