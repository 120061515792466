/** @jsx jsx */
import type { ReactElement, ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { Heading } from '@balance-web/heading';
import { Stack } from '@balance-web/stack';
import { forwardRefWithAs, makeId, useId } from '@balance-web/utils';
import { Text } from '@balance-web/text';
import { Box } from '@balance-web/box';

import type { ModalSize } from './size';
import { useModalWidth } from './size';
import { DialogBase } from './DialogBase';

type PassiveDialogProps = {
  /** Use any of our spot or hero illustrations here. */
  illustration?: ReactElement;
  children: ReactNode;
  title?: string;
  id?: string;
  isOpen: boolean;
  size?: ModalSize;
};

export const PassiveDialog = forwardRefWithAs<'div', PassiveDialogProps>(
  (
    { children, illustration, title, id, isOpen, size = 'small', ...props },
    ref
  ) => {
    const instanceId = useId(id);
    const headingId = makeId(instanceId, 'heading');

    const modalWidth = useModalWidth(size);

    return (
      <DialogBase
        id={instanceId}
        isOpen={isOpen}
        onClose={() => {}}
        width={modalWidth}
        ref={ref}
        aria-labelledby={headingId}
        autoFocus={false}
        {...props}
      >
        <Stack
          align="center"
          paddingBottom="xxlarge"
          css={{
            textAlign: 'center',
          }}
        >
          {illustration && (
            <Box paddingTop="xlarge" paddingBottom="large">
              {illustration}
            </Box>
          )}
          <Stack paddingTop="large" gap="medium">
            {title && (
              <Heading level="4" as="h2" id={headingId}>
                {title}
              </Heading>
            )}
            <Text color="muted">{children}</Text>
          </Stack>
        </Stack>
      </DialogBase>
    );
  }
);
