/** @jsx jsx */

import { CSSProperties, useMemo } from 'react';
import { Box, BoxProps } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { forwardRefWithAs } from '@balance-web/utils';

import { TextContext, useTextContext } from './context';
import { useDefaultTextProps } from './defaultTextProps';
import { UseTextProps, useText } from './useText';
import {
  OverflowStrategyType,
  useOverflowStrategy,
} from './useOverflowStrategy';

type ValidBoxProps = Pick<BoxProps, 'cursor' | 'data' | 'id' | 'userSelect'>;

export type TextProps = UseTextProps & {
  /** The horizontal alignment. */
  align?: CSSProperties['textAlign'];
  /** Display as an inline-level element. */
  inline?: boolean;
  /** Manage how text behaves with regard to overflow. */
  overflowStrategy?: OverflowStrategyType;
  /**
   * Truncate the text with an ellipsis after computing the text layout,
   * including line wrapping, such that the total number of lines does not
   * exceed this number.
   *
   * Will override the `overflowStrategy` prop.
   */
  numberOfLines?: number;
  /** Enable to make numbers the same width. */
  tabularNumbers?: boolean;
  /** Transform the text casing. */
  transform?: CSSProperties['textTransform'];
} & ValidBoxProps;

export const Text = forwardRefWithAs<'div', TextProps>(
  (
    {
      // box props
      as,
      data,
      id,
      cursor,
      userSelect,

      // text props
      align,
      color: colorProp,
      inline,
      leading: leadingProp,
      numberOfLines,
      overflowStrategy = 'wrap',
      size: sizeProp,
      weight: weightProp,
      tabularNumbers,
      transform,
      ...props
    },
    ref
  ) => {
    const { typography } = useTheme();
    const overflowStyles = useOverflowStrategy(overflowStrategy, numberOfLines);
    const textContext = useTextContext();
    const { color, leading, weight, size } = useDefaultTextProps({
      color: colorProp ?? textContext?.color,
      leading: leadingProp ?? textContext?.leading,
      size: sizeProp ?? textContext?.size,
      weight: weightProp ?? textContext?.weight,
    });
    const textStyles = useText({ color, leading, size, weight });
    const textContextValue = useMemo(() => ({ color, leading, size, weight }), [
      color,
      leading,
      size,
      weight,
    ]);

    const resolvedAsProp = as || (inline ? 'span' : 'div');

    const styles = [
      {
        display: inline ? 'inline' : 'block',
        fontVariantNumeric: tabularNumbers ? 'tabular-nums' : undefined,
        fontFamily: typography.fontFamily.body,
        textAlign: align,
        textTransform: transform,
      },
      textStyles,
      overflowStyles,
    ];

    return (
      <TextContext.Provider value={textContextValue}>
        <Box
          as={resolvedAsProp}
          css={styles}
          ref={ref}
          cursor={cursor}
          data={data}
          id={id}
          userSelect={userSelect}
          {...props}
        />
      </TextContext.Provider>
    );
  }
);

Text.displayName = 'Text';
