const LOCALE = 'en-AU';
const CURRENCY = 'AUD';
const MAXIMUM_FRACTION_DIGITS = 8;

type Indicator = 'symbol' | 'code' | 'none';
type Options = {
  /**
   * Adorn the returned string with an indicator.
   * @default 'symbol'
   * @example
   * formatCurrency(123456, { indicator: 'code' }) // 'AUD 123,456.00'
   * formatCurrency(123456, { indicator: 'symbol' }) // '$123,456.00'
   * formatCurrency(123456, { indicator: 'none' }) // '123,456.00'
   */
  indicator?: Indicator;
  /**
   * Enforce the number of fraction digits.
   * @default 2
   * @example
   * formatCurrency(123456, { precision: 4 }) // '$123,456.0000'
   * formatCurrency(123456, { precision: -1 }) // '$123,456'
   * formatCurrency(123456.789, { precision: -1 }) // '$123,456.789'
   */
  precision?: number;
};

/** Format a numeric value into a currency string. */
export function formatCurrency(value: number, options: Options = {}) {
  const fractionDigits = getFractionDigits(options.precision);
  const style = getStyle(options.indicator);

  return value.toLocaleString(LOCALE, {
    ...style,
    ...fractionDigits,
  });
}

function getFractionDigits(precision = 2) {
  if (precision === -1) {
    return { maximumFractionDigits: MAXIMUM_FRACTION_DIGITS };
  }

  return { maximumFractionDigits: precision, minimumFractionDigits: precision };
}

function getStyle(indicator: Indicator = 'symbol') {
  if (indicator === 'none') {
    return { style: 'decimal' };
  }

  return { style: 'currency', currency: CURRENCY, currencyDisplay: indicator };
}
