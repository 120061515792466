import React from 'react';
import { Box } from '@balance-web/box';
import { TextLink, TextLinkProps } from '@balance-web/text-link';
import { Text } from '@balance-web/text';

export type BannerActionProps = TextLinkProps;

export const BannerAction = (props: BannerActionProps) => {
  return (
    <Box paddingLeft="small">
      <Text size="small">
        <TextLink {...props} />
      </Text>
    </Box>
  );
};
