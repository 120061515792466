import React from 'react';
import type { ReactNode } from 'react';

import { ElevateContext } from './context';
import { useElevate } from './useElevate';
import { useOnElevate } from './maxElevationHooks';

const ZINDEX_INCREMENT = 100000;

/**
 * This component is only suitable when you want to elevate elements rendered hierarchically like Dropdowns.
 * It doesn't work for components that require elevation but are only rendered once near the top of the React tree like Toast.
 */
export const Elevate = ({ children }: { children?: ReactNode }) => {
  const { zIndex } = useElevate({ zIndex: ZINDEX_INCREMENT });
  useOnElevate();

  return (
    <ElevateContext.Provider value={{ zIndex }}>
      {children}
    </ElevateContext.Provider>
  );
};
