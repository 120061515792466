import React from 'react';
import { ReactNode, createContext, useContext, useMemo } from 'react';

import { UseTextProps } from './useText';

// TODO: remove "leading" prop

type DefaultTextProps = {
  color?: NonNullable<UseTextProps['color']>;
  leading?: NonNullable<UseTextProps['leading']>;
  size?: NonNullable<UseTextProps['size']>;
  weight?: NonNullable<UseTextProps['weight']>;
};

const DefaultTextPropsContext = createContext<DefaultTextProps>({
  color: undefined,
  leading: undefined,
  size: undefined,
  weight: undefined,
});

type DefaultTextPropsProviderProps = {
  children: ReactNode;
} & DefaultTextProps;

export const DefaultTextPropsProvider = ({
  children,
  color,
  size,
  leading,
  weight,
}: DefaultTextPropsProviderProps) => {
  const defaultTextProps = useMemo(() => ({ color, leading, size, weight }), [
    color,
    leading,
    size,
    weight,
  ]);

  return (
    <DefaultTextPropsContext.Provider value={defaultTextProps}>
      {children}
    </DefaultTextPropsContext.Provider>
  );
};

export const useDefaultTextProps = ({
  color: colorProp,
  leading: leadingProp,
  size: sizeProp,
  weight: weightProp,
}: DefaultTextProps) => {
  const { color, leading, size, weight } = useContext(DefaultTextPropsContext);

  return {
    color: colorProp ?? color ?? 'base',
    size: sizeProp ?? size ?? 'medium',
    leading: leadingProp ?? leading ?? 'base',
    weight: weightProp ?? weight ?? 'regular',
  };
};
