import * as React from 'react';

import { createIcon } from '../Icon';
export const CodeIcon = createIcon(
  <React.Fragment>
    <polyline points="16 18 22 12 16 6" />
    <polyline points="8 6 2 12 8 18" />
  </React.Fragment>,
  'CodeIcon'
);
