import React, { AnchorHTMLAttributes } from 'react';
// eslint-disable-next-line no-restricted-imports
import Link, { LinkProps } from 'next/link';

type Props = { href: LinkProps['href'] } & Omit<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  'href'
>;

/** @deprecated `NextLink` has been deprecated. Use [Link](https://balance.reckon.com/package/link) instead. */
export const NextLink = ({ href, ...props }: Props) => (
  <Link href={href} passHref>
    <a {...props} />
  </Link>
);
