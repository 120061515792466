import { useTheme } from '@balance-web/theme';
export type ModalSize = 'small' | 'medium' | 'large';

export const useModalWidth = (size: ModalSize) => {
  const theme = useTheme();

  if (size === 'small') return theme.contentWidth.xsmall;
  if (size === 'medium') return theme.contentWidth.small;
  return theme.contentWidth.large;
};
