import * as React from 'react';

import { createIcon } from '../Icon';
export const PieChartIcon = createIcon(
  <React.Fragment>
    <path d="M21.21 15.89A10 10 0 1 1 8 2.83" />
    <path d="M22 12A10 10 0 0 0 12 2v10z" />
  </React.Fragment>,
  'PieChartIcon'
);
