/** @jsx jsx */

import { createContext, useContext, useMemo } from 'react';
import type { ElementType, ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { useRawTheme } from '@balance-web/theme';
import { forwardRefWithAs } from '@balance-web/utils';

import type { InputShapeType, InputSizeType } from './types';

/**
 * What is this thing?
 * ------------------------------
 * We expose primitive components for adorning inputs with icons and buttons.
 * There's some awkard requirements surrounding size and shape that's best to
 * consolidate in one place.
 */

const AdornmentContext = createContext<{
  shape: InputShapeType;
  size: InputSizeType;
}>({
  shape: 'square',
  size: 'medium',
});
const useAdornmentContext = () => {
  return useContext(AdornmentContext);
};

// Adornment Wrapper
// ------------------------------

export type AdornmentWrapperProps = {
  children: ReactNode;
  shape?: InputShapeType;
  size?: InputSizeType;
};

export const AdornmentWrapper = ({
  children,
  shape = 'square',
  size = 'medium',
  ...props
}: AdornmentWrapperProps) => {
  return (
    <AdornmentContext.Provider value={{ shape, size }}>
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          position: 'relative',
        }}
        {...props}
      >
        {children}
      </div>
    </AdornmentContext.Provider>
  );
};

// Adornment Element
// ------------------------------

const alignmentPaddingMap = {
  left: 'marginLeft',
  right: 'marginRight',
};

type AdornmentProps = {
  align: 'left' | 'right';
  as?: ElementType;
};
export const Adornment = forwardRefWithAs<'div', AdornmentProps>(
  ({ align, as: Tag = 'div', ...props }, ref) => {
    const { sizing, spacing } = useRawTheme();
    const { shape, size } = useAdornmentContext();

    const { boxSize, paddingX } = useMemo(() => {
      if (size === 'small') {
        return {
          boxSize: sizing.small,
          paddingX: spacing.medium,
        };
      }

      return {
        boxSize: sizing.base,
        paddingX: spacing.large,
      };
    }, [size, sizing.base, sizing.small, spacing.large, spacing.medium]);

    // optical alignment shifts towards the middle of the container with the large
    // border radius on "round" inputs. use padding rather than margin to optimise
    // the hit-area of interactive elements
    const offsetStyles =
      shape === 'round'
        ? {
            [alignmentPaddingMap[align]]: paddingX / 4,
          }
        : null;

    return (
      <Tag
        ref={ref}
        css={{
          [align]: 0,
          alignItems: 'center',
          display: 'flex',
          height: boxSize,
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          width: boxSize,
          ...offsetStyles,
        }}
        {...props}
      />
    );
  }
);
