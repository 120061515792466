import React, { createContext, useContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';

type BannerContextType = {
  pushAppBanner: () => void;
  popAppBanner: () => void;
  pushGlobalBanner: () => void;
  popGlobalBanner: () => void;
};

const BannerContext = createContext<BannerContextType | null>(null);

export const BannerProvider = ({ children }: { children?: ReactNode }) => {
  const [appBannerCount, setAppBannerCount] = useState(0);
  const [globalBannerCount, setGlobalBannerCount] = useState(0);

  const pushAppBanner = () => setAppBannerCount((v) => v + 1);
  const popAppBanner = () => setAppBannerCount((v) => v - 1);

  const pushGlobalBanner = () => setGlobalBannerCount((v) => v + 1);
  const popGlobalBanner = () => setGlobalBannerCount((v) => v - 1);

  if (appBannerCount > 1)
    throw Error('Only 1 instance of AppBanner can be displayed at a time.');
  if (globalBannerCount > 1)
    throw Error('Only 1 instance of GlobalBanner can be displayed at a time.');

  return (
    <BannerContext.Provider
      value={{ pushAppBanner, popAppBanner, pushGlobalBanner, popGlobalBanner }}
    >
      {children}
    </BannerContext.Provider>
  );
};

const useBannerContext = () => {
  const context = useContext(BannerContext);

  if (!context)
    throw Error(
      'AppBanner and GlobalBanner can only be used inside BannerProvider.'
    );

  return context;
};

export const useGlobalBanner = () => {
  const { pushGlobalBanner, popGlobalBanner } = useBannerContext();

  useEffect(() => {
    pushGlobalBanner();

    return popGlobalBanner;
  }, [popGlobalBanner, pushGlobalBanner]);
};

export const useAppBanner = () => {
  const { pushAppBanner, popAppBanner } = useBannerContext();

  useEffect(() => {
    pushAppBanner();

    return popAppBanner;
  }, [popAppBanner, pushAppBanner]);
};
