/** @jsx jsx */

import { CSSProperties, ReactNode } from 'react';
import { Box, BoxProps } from '@balance-web/box';
import { ResponsiveProp, jsx } from '@balance-web/core';
import { useMediaQuery } from '@balance-web/media-query';
import { BalanceTheme, useTheme } from '@balance-web/theme';
import { forwardRefWithAs } from '@balance-web/utils';

export type FlexProps = {
  /** The content of this flex element. */
  children?: ReactNode;
  /** The value of the "align-items" property. */
  alignItems?: ResponsiveProp<CSSProperties['alignItems']>;
  /** The size of the gap between each child element. */
  gap?: ResponsiveProp<keyof BalanceTheme['spacing']>;
  /** Defines the main axis, or how the children are placed. */
  direction?: ResponsiveProp<CSSProperties['flexDirection']>;
  /** Display as an inline-level flex container. */
  inline?: boolean;
  /** The value of the "justify-content" property. */
  justifyContent?: ResponsiveProp<CSSProperties['justifyContent']>;
  /** Allow flex items to flow onto multiple lines. */
  wrap?: boolean;
} & BoxProps;

export const Flex = forwardRefWithAs<'div', FlexProps>((props, ref) => {
  const { spacing } = useTheme();
  const { mq, mapResponsiveProp } = useMediaQuery();
  const {
    alignItems = 'stretch',
    gap = 'none',
    inline = false,
    justifyContent = 'start',
    direction = 'row',
    wrap = false,
    ...boxProps
  } = props;

  let styles = mq({
    alignItems,
    display: inline ? 'inline-flex' : 'flex',
    flexDirection: direction,
    flexWrap: wrap ? 'wrap' : 'nowrap',
    gap: mapResponsiveProp(gap, spacing),
    justifyContent,
  });

  return <Box ref={ref} css={styles} {...boxProps} />;
});

Flex.displayName = 'Flex';
