export const lightThemeCSSVars = {
  ':root': {
    '--colors-background': '#ffffff',
    '--colors-text': '#181A20',
    '--colors-primary': '#5A3FFF',
    '--colors-secondary': '#0a004a',
    '--colors-brand': '#F84184',
    '--colors-accent': '#DF322A',
    '--colors-danger': '#C0000C',
    '--colors-warning': '#FFB027',
    '--colors-success': '#009754',
    '--colors-info': '#0091EA',
    '--colors-decorative': '#BDE7FF,#B1FAE0,#FFF0B3,#FFB7B2,#FFB1D8,#CBC1F4,#AFD5FF',
    '--colors-variant': '#BDE7FF,#B1FAE0,#FFF0B3,#FFB7B2,#FFB1D8,#CBC1F4,#AFD5FF',
    '--components-chip-border-radius': '8px',
    '--components-chip-filled-surface-color': '#F9F7FF',
    '--components-chip-filled-border-color': '#DDDEE9',
    '--components-chip-filled-icon-color': '#52535C',
    '--components-chip-filled-text-color': '#52535C',
    '--components-chip-filled-interactions-hover-surface-color': '#1B1B1F14',
    '--components-chip-filled-interactions-pressed-surface-color': '#1B1B1F1F',
    '--components-chip-filled-active-surface-color': '#E1DAFF',
    '--components-chip-filled-active-border-color': '#E1DAFF',
    '--components-chip-filled-active-icon-color': '#0a004a',
    '--components-chip-filled-active-text-color': '#0a004a',
    '--components-chip-filled-active-interactions-hover-surface-color': '#14005D14',
    '--components-chip-filled-active-interactions-pressed-surface-color': '#14005D1F',
    '--components-chip-outline-surface-color': '#ffffff',
    '--components-chip-outline-border-color': '#DDDEE9',
    '--components-chip-outline-icon-color': '#52535C',
    '--components-chip-outline-text-color': '#52535C',
    '--components-chip-outline-interactions-hover-surface-color': '#1B1B1F14',
    '--components-chip-outline-interactions-pressed-surface-color': '#1B1B1F1F',
    '--components-chip-outline-active-surface-color': '#E1DAFF',
    '--components-chip-outline-active-border-color': '#E1DAFF',
    '--components-chip-outline-active-icon-color': '#0a004a',
    '--components-chip-outline-active-text-color': '#0a004a',
    '--components-chip-outline-active-interactions-hover-surface-color': '#14005D14',
    '--components-chip-outline-active-interactions-pressed-surface-color': '#14005D1F',
    '--components-chip-small-height': '32px',
    '--components-chip-small-padding-inline': '12px',
    '--components-chip-medium-height': '40px',
    '--components-chip-medium-padding-inline': '16px',
    '--components-chip-disabled-surface-color': '#5E5E620A',
    '--components-chip-disabled-text-color': '#BDBFCA',
    '--components-chip-disabled-icon-color': '#BDBFCA',
    '--name': 'Reckon web',
    '--border-width-standard': '1px',
    '--border-width-large': '2px',
    '--breakpoints-small': '576px',
    '--breakpoints-medium': '768px',
    '--breakpoints-large': '882px',
    '--breakpoints-xlarge': '1200px',
    '--content-width-xsmall': '480px',
    '--content-width-small': '720px',
    '--content-width-medium': '1024px',
    '--content-width-large': '1200px',
    '--content-width-xlarge': '1440px',
    '--elevation-card': '100',
    '--elevation-dropdown': '200',
    '--elevation-sticky': '300',
    '--elevation-modal': '400',
    '--elevation-popover': '500',
    '--elevation-toast': '600',
    '--shadow-xsmall': '0px 1px 2px rgba(0, 0, 0, 0.2)',
    '--shadow-small': '0px 2px 4px rgba(0, 0, 0, 0.2)',
    '--shadow-medium': '0px 2px 8px rgba(0, 0, 0, 0.2)',
    '--shadow-large': '0px 4px 16px rgba(0, 0, 0, 0.2)',
    '--shadow-xlarge': '-8px 8px 32px rgba(0, 0, 0, 0.2)',
    '--palette-decorative': '#FFCA3A,#12714D,#106F85,#1D6FEA,#8246D4,#C31F83,#B94018',
    '--palette-variant': '#BDE7FF,#B1FAE0,#FFF0B3,#FFB7B2,#FFB1D8,#CBC1F4,#AFD5FF',
    '--palette-actions-passive': '#585c62',
    '--palette-actions-active': '#5A3FFF',
    '--palette-actions-critical': '#C0000C',
    '--palette-global-accent': '#DF322A',
    '--palette-global-border': '#e4e5e6',
    '--palette-global-focus-ring': 'rgba(0,122,255,0.2)',
    '--palette-global-loading': '#5A3FFF',
    '--palette-border-standard': '#e4e5e6',
    '--palette-border-muted': '#f2f2f2',
    '--palette-border-accent': '#d0cee5',
    '--palette-border-active': '#c2dfff',
    '--palette-border-cautious': '#ffeccb',
    '--palette-border-critical': '#f8c3c6',
    '--palette-border-informative': '#c2e5fa',
    '--palette-border-positive': '#c2e6d6',
    '--palette-background-base': '#ffffff',
    '--palette-background-muted': '#f8f8f9',
    '--palette-background-dim': '#f2f2f2',
    '--palette-background-shade': '#ebebec',
    '--palette-background-dialog': '#FCFBFF',
    '--palette-background-accent': '#e0deed',
    '--palette-background-selectable-hover': '#fbfbfb',
    '--palette-background-selectable-pressed': '#f6f6f7',
    '--palette-background-selectable-selected': '#f5faff',
    '--palette-background-selectable-selected-hover': '#ebf4ff',
    '--palette-background-active': '#d6eaff',
    '--palette-background-cautious': '#fff2dc',
    '--palette-background-critical': '#fbd7d9',
    '--palette-background-informative': '#d6edfc',
    '--palette-background-positive': '#d6eee4',
    '--palette-background-active-muted': '#cce4ff',
    '--palette-background-cautious-muted': '#ffefd4',
    '--palette-background-critical-muted': '#f9cdd0',
    '--palette-background-informative-muted': '#cce9fb',
    '--palette-background-positive-muted': '#cceadd',
    '--palette-text-base': '#181A20',
    '--palette-text-muted': '#585c62',
    '--palette-text-dim': '#909396',
    '--palette-text-link': '#5A3FFF',
    '--palette-text-link-hover': '#3689ff',
    '--palette-text-link-visited': '#3C3391',
    '--palette-text-accent': '#3C3391',
    '--palette-text-active': '#5A3FFF',
    '--palette-text-cautious': '#B35400',
    '--palette-text-critical': '#c0000c',
    '--palette-text-informative': '#0091EA',
    '--palette-text-positive': '#009754',
    '--palette-heading-base': '#0a004a',
    '--palette-heading-muted': '#585c62',
    '--palette-heading-dim': '#909396',
    '--palette-heading-accent': '#3C3391',
    '--palette-heading-active': '#5A3FFF',
    '--palette-heading-cautious': '#B35400',
    '--palette-heading-critical': '#E30613',
    '--palette-heading-informative': '#0091EA',
    '--palette-heading-positive': '#009754',
    '--palette-form-input-background': '#ffffff',
    '--palette-form-input-background-hovered': '#f8f8f9',
    '--palette-form-input-background-focused': '#ffffff',
    '--palette-form-input-background-disabled': '#f8f8f9',
    '--palette-form-input-background-invalid': '#fbd7d9',
    '--palette-form-input-border': '#e4e5e6',
    '--palette-form-input-border-hovered': '#e4e5e6',
    '--palette-form-input-border-focused': '#5A3FFF',
    '--palette-form-input-border-disabled': '#e4e5e6',
    '--palette-form-input-border-invalid': '#c0000c',
    '--palette-form-input-text': '#181A20',
    '--palette-form-input-text-disabled': '#C9CBCD',
    '--palette-form-input-text-placeholder': '#C9CBCD',
    '--palette-form-input-text-invalid': '#E30613',
    '--palette-form-control-background': '#ffffff',
    '--palette-form-control-background-disabled': '#e4e5e6',
    '--palette-form-control-border': '#e4e5e6',
    '--palette-form-control-interaction': '#DF322A',
    '--palette-form-control-foreground-checked': '#ffffff',
    '--palette-form-control-foreground-disabled': '#909396',
    '--palette-segmented-control-track': '#f2f2f2',
    '--palette-segmented-control-divider': 'rgba(32,38,45,0.16)',
    '--palette-segmented-control-background-selected': '#ffffff',
    '--palette-segmented-control-text': '#585c62',
    '--palette-segmented-control-text-focused': '#181A20',
    '--palette-segmented-control-text-pressed': '#181A20',
    '--palette-segmented-control-text-selected': '#181A20',
    '--palette-toggle-track': '#e4e5e6',
    '--palette-toggle-track-checked': '#007AFF',
    '--palette-toggle-track-disabled': '#f2f2f2',
    '--palette-toggle-track-disabled-checked': '#909396',
    '--palette-toggle-handle': 'white',
    '--palette-list-item-background-focused': '#edeeee',
    '--palette-list-item-background-pressed': '#e4e5e6',
    '--palette-list-item-background-selected': '#e4e5e6',
    '--palette-list-item-divider': '#e4e5e6',
    '--palette-list-item-text': '#181A20',
    '--palette-list-item-text-focused': '#181A20',
    '--palette-list-item-text-pressed': '#181A20',
    '--palette-list-item-text-selected': '#181A20',
    '--palette-table-row-background-cautious': '#fffaf2',
    '--palette-table-row-background-critical': '#fdf0f1',
    '--palette-table-row-background-positive': '#f0f9f5',
    '--palette-table-row-background-disabled': '#E0E0E0',
    '--palette-table-row-drop-background-valid': '#AAD5FF',
    '--palette-table-row-drop-background-invalid': '#f9cdd0',
    '--palette-table-cell-border-disabled': '#939393',
    '--palette-menu-item-background-focused': '#f2f2f2',
    '--palette-menu-item-background-pressed': '#e4e5e6',
    '--palette-menu-item-background-selected': '#f0f7ff',
    '--palette-menu-item-divider': '#e4e5e6',
    '--palette-menu-item-text': '#181A20',
    '--palette-menu-item-text-disabled': '#909396',
    '--palette-menu-item-text-focused': '#181A20',
    '--palette-menu-item-text-pressed': '#181A20',
    '--palette-menu-item-text-selected': '#181A20',
    '--palette-tooltip-background': '#585c62',
    '--palette-tooltip-text': '#ffffff',
    '--palette-button-active-background': '#5A3FFF',
    '--palette-button-active-background-focused': '#6e56ff',
    '--palette-button-active-background-pressed': '#6e56ff',
    '--palette-button-critical-background': '#C0000C',
    '--palette-button-critical-background-focused': '#e2423a',
    '--palette-button-critical-background-pressed': '#e34b44',
    '--palette-button-passive-background': '#585c62',
    '--palette-button-passive-background-focused': '#5f6369',
    '--palette-button-passive-background-pressed': '#4e5258',
    '--palette-action-button-background': '#ffffff',
    '--palette-action-button-background-focused': '#f2f2f2',
    '--palette-action-button-background-pressed': '#e4e5e6',
    '--palette-action-button-background-selected': '#e4e5e6',
    '--palette-action-button-border': '#e4e5e6',
    '--palette-action-button-border-focused': '#dbdcdd',
    '--palette-action-button-border-pressed': '#c9cbcd',
    '--palette-action-button-border-selected': '#c9cbcd',
    '--palette-action-button-text': '#585c62',
    '--palette-action-button-text-focused': '#181A20',
    '--palette-action-button-text-pressed': '#181A20',
    '--palette-action-button-text-selected': '#181A20',
    '--palette-interactive-base-background': '#ffffff',
    '--palette-interactive-base-hover': '#f2f2f2',
    '--palette-interactive-base-active': '#e4e5e6',
    '--primitives-colour-primary-k0': '#000000',
    '--primitives-colour-primary-k10': '#0a004a',
    '--primitives-colour-primary-k20': '#210098',
    '--primitives-colour-primary-k30': '#3000dd',
    '--primitives-colour-primary-k40': '#5a3fff',
    '--primitives-colour-primary-k50': '#6652ff',
    '--primitives-colour-primary-k60': '#8274ff',
    '--primitives-colour-primary-k70': '#a096ff',
    '--primitives-colour-primary-k80': '#c0b7ff',
    '--primitives-colour-primary-k90': '#e1daff',
    '--primitives-colour-primary-k95': '#f2ebff',
    '--primitives-colour-primary-k99': '#fffaff',
    '--primitives-colour-primary-k100': '#ffffff',
    '--primitives-colour-primary-interactions-default-light': '#ffffff14',
    '--primitives-colour-primary-interactions-default-mid': '#ffffff1f',
    '--primitives-colour-primary-interactions-default-high': '#ffffff29',
    '--primitives-colour-primary-interactions-container-light': '#14005d14',
    '--primitives-colour-primary-interactions-container-mid': '#14005d1f',
    '--primitives-colour-primary-interactions-container-high': '#14005d29',
    '--primitives-colour-primary-interactions-inverse-light': '#5a3fff14',
    '--primitives-colour-primary-interactions-inverse-mid': '#5a3fff1f',
    '--primitives-colour-primary-interactions-inverse-high': '#5a3fff29',
    '--primitives-colour-secondary-k0': '#000000',
    '--primitives-colour-secondary-k10': '#370e0c',
    '--primitives-colour-secondary-k20': '#6f1a16',
    '--primitives-colour-secondary-k30': '#a72620',
    '--primitives-colour-secondary-k40': '#df322a',
    '--primitives-colour-secondary-k50': '#e6544b',
    '--primitives-colour-secondary-k60': '#ec746c',
    '--primitives-colour-secondary-k70': '#f2948d',
    '--primitives-colour-secondary-k80': '#f8b4ae',
    '--primitives-colour-secondary-k90': '#fed4cf',
    '--primitives-colour-secondary-k95': '#ffeae6',
    '--primitives-colour-secondary-k99': '#fcfbfc',
    '--primitives-colour-secondary-k100': '#ffffff',
    '--primitives-colour-secondary-interactions-default-light': '#ffffff14',
    '--primitives-colour-secondary-interactions-default-mid': '#ffffff1f',
    '--primitives-colour-secondary-interactions-default-high': '#ffffff29',
    '--primitives-colour-secondary-interactions-container-light': '#370e0c14',
    '--primitives-colour-secondary-interactions-container-mid': '#370e0c1f',
    '--primitives-colour-secondary-interactions-container-high': '#370e0c29',
    '--primitives-colour-secondary-interactions-inverse-light': '#df322a14',
    '--primitives-colour-secondary-interactions-inverse-mid': '#df322a1f',
    '--primitives-colour-secondary-interactions-inverse-high': '#df322a29',
    '--primitives-colour-neutral-k0': '#000000',
    '--primitives-colour-neutral-k10': '#181a20',
    '--primitives-colour-neutral-k20': '#292a31',
    '--primitives-colour-neutral-k25': '#32343b',
    '--primitives-colour-neutral-k30': '#3c3d46',
    '--primitives-colour-neutral-k35': '#464850',
    '--primitives-colour-neutral-k40': '#52535c',
    '--primitives-colour-neutral-k50': '#6a6c75',
    '--primitives-colour-neutral-k60': '#84858f',
    '--primitives-colour-neutral-k70': '#a0a1ab',
    '--primitives-colour-neutral-k80': '#bdbfca',
    '--primitives-colour-neutral-k90': '#dddee9',
    '--primitives-colour-neutral-k95': '#eeeef9',
    '--primitives-colour-neutral-k98': '#f9f7ff',
    '--primitives-colour-neutral-k99': '#fcfbff',
    '--primitives-colour-neutral-k100': '#ffffff',
    '--primitives-colour-neutral-interactions-default-light': '#ffffff14',
    '--primitives-colour-neutral-interactions-default-mid': '#ffffff1f',
    '--primitives-colour-neutral-interactions-default-high': '#ffffff29',
    '--primitives-colour-neutral-interactions-container-light': '#1b1b1f14',
    '--primitives-colour-neutral-interactions-container-mid': '#1b1b1f1f',
    '--primitives-colour-neutral-interactions-container-high': '#1b1b1f29',
    '--primitives-colour-neutral-interactions-inverse-light': '#5e5e6214',
    '--primitives-colour-neutral-interactions-inverse-mid': '#5e5e621f',
    '--primitives-colour-neutral-interactions-inverse-high': '#5e5e6229',
    '--primitives-colour-reserved-critical-k0': '#000000',
    '--primitives-colour-reserved-critical-k10': '#410001',
    '--primitives-colour-reserved-critical-k20': '#690003',
    '--primitives-colour-reserved-critical-k30': '#930007',
    '--primitives-colour-reserved-critical-k40': '#c0000c',
    '--primitives-colour-reserved-critical-k50': '#ec1419',
    '--primitives-colour-reserved-critical-k60': '#ff5447',
    '--primitives-colour-reserved-critical-k70': '#ff8a7c',
    '--primitives-colour-reserved-critical-k80': '#ffb4aa',
    '--primitives-colour-reserved-critical-k90': '#ffdad5',
    '--primitives-colour-reserved-critical-k95': '#ffedea',
    '--primitives-colour-reserved-critical-k99': '#fffbff',
    '--primitives-colour-reserved-critical-k100': '#ffffff',
    '--primitives-colour-reserved-critical-interactions-default-light': '#ffffff14',
    '--primitives-colour-reserved-critical-interactions-default-mid': '#ffffff1f',
    '--primitives-colour-reserved-critical-interactions-default-high': '#ffffff29',
    '--primitives-colour-reserved-critical-interactions-container-light': '#41000114',
    '--primitives-colour-reserved-critical-interactions-container-mid': '#4100011f',
    '--primitives-colour-reserved-critical-interactions-container-high': '#41000129',
    '--primitives-colour-reserved-critical-interactions-inverse-light': '#c0000c14',
    '--primitives-colour-reserved-critical-interactions-inverse-mid': '#c0000c1f',
    '--primitives-colour-reserved-critical-interactions-inverse-high': '#c0000c29',
    '--primitives-colour-reserved-info-k0': '#000000',
    '--primitives-colour-reserved-info-k10': '#001d35',
    '--primitives-colour-reserved-info-k20': '#003256',
    '--primitives-colour-reserved-info-k30': '#00497a',
    '--primitives-colour-reserved-info-k40': '#0062a0',
    '--primitives-colour-reserved-info-k50': '#007bc8',
    '--primitives-colour-reserved-info-k60': '#1596ef',
    '--primitives-colour-reserved-info-k70': '#5db0ff',
    '--primitives-colour-reserved-info-k80': '#9ccaff',
    '--primitives-colour-reserved-info-k90': '#d0e4ff',
    '--primitives-colour-reserved-info-k95': '#e9f1ff',
    '--primitives-colour-reserved-info-k99': '#fdfcff',
    '--primitives-colour-reserved-info-k100': '#ffffff',
    '--primitives-colour-reserved-info-interactions-default-light': '#ffffff14',
    '--primitives-colour-reserved-info-interactions-default-mid': '#ffffff1f',
    '--primitives-colour-reserved-info-interactions-default-high': '#ffffff29',
    '--primitives-colour-reserved-info-interactions-container-light': '#001d3514',
    '--primitives-colour-reserved-info-interactions-container-mid': '#001d351f',
    '--primitives-colour-reserved-info-interactions-container-high': '#001d3529',
    '--primitives-colour-reserved-info-interactions-inverse-light': '#0062a014',
    '--primitives-colour-reserved-info-interactions-inverse-mid': '#0062a01f',
    '--primitives-colour-reserved-info-interactions-inverse-high': '#0062a029',
    '--primitives-colour-reserved-warning-k0': '#000000',
    '--primitives-colour-reserved-warning-k10': '#2a1800',
    '--primitives-colour-reserved-warning-k20': '#462b00',
    '--primitives-colour-reserved-warning-k30': '#633f00',
    '--primitives-colour-reserved-warning-k40': '#835500',
    '--primitives-colour-reserved-warning-k50': '#a46b00',
    '--primitives-colour-reserved-warning-k60': '#c68200',
    '--primitives-colour-reserved-warning-k70': '#ea9b00',
    '--primitives-colour-reserved-warning-k80': '#ffb94b',
    '--primitives-colour-reserved-warning-k90': '#ffddb0',
    '--primitives-colour-reserved-warning-k95': '#ffeed9',
    '--primitives-colour-reserved-warning-k99': '#fcfcfc',
    '--primitives-colour-reserved-warning-k100': '#ffffff',
    '--primitives-colour-reserved-warning-interactions-light-default-light': '#ffffff14',
    '--primitives-colour-reserved-warning-interactions-light-default-mid': '#ffffff1f',
    '--primitives-colour-reserved-warning-interactions-light-default-high': '#ffffff29',
    '--primitives-colour-reserved-warning-interactions-light-container-light': '#2a180014',
    '--primitives-colour-reserved-warning-interactions-light-container-mid': '#2a18001f',
    '--primitives-colour-reserved-warning-interactions-light-container-high': '#2a180029',
    '--primitives-colour-reserved-warning-interactions-light-inverse-light': '#83550014',
    '--primitives-colour-reserved-warning-interactions-light-inverse-mid': '#8355001f',
    '--primitives-colour-reserved-warning-interactions-light-inverse-high': '#83550029',
    '--primitives-colour-reserved-success-k0': '#000000',
    '--primitives-colour-reserved-success-k10': '#00210e',
    '--primitives-colour-reserved-success-k20': '#00391c',
    '--primitives-colour-reserved-success-k30': '#00522b',
    '--primitives-colour-reserved-success-k40': '#006d3b',
    '--primitives-colour-reserved-success-k50': '#00894c',
    '--primitives-colour-reserved-success-k60': '#23a560',
    '--primitives-colour-reserved-success-k70': '#47c179',
    '--primitives-colour-reserved-success-k80': '#65dd92',
    '--primitives-colour-reserved-success-k90': '#83faac',
    '--primitives-colour-reserved-success-k95': '#c2ffd0',
    '--primitives-colour-reserved-success-k99': '#f5fff3',
    '--primitives-colour-reserved-success-k100': '#ffffff',
    '--primitives-colour-reserved-success-interactions-default-light': '#ffffff14',
    '--primitives-colour-reserved-success-interactions-default-mid': '#ffffff1f',
    '--primitives-colour-reserved-success-interactions-default-high': '#ffffff29',
    '--primitives-colour-reserved-success-interactions-container-light': '#00210e14',
    '--primitives-colour-reserved-success-interactions-container-mid': '#00210e1f',
    '--primitives-colour-reserved-success-interactions-container-high': '#00210e29',
    '--primitives-colour-reserved-success-interactions-inverse-light': '#006d3b14',
    '--primitives-colour-reserved-success-interactions-inverse-mid': '#006d3b1f',
    '--primitives-colour-reserved-success-interactions-inverse-high': '#006d3b29',
    '--primitives-colour-surface-default-k0': '#ffffff',
    '--primitives-colour-surface-default-k1': '#fcfbff',
    '--primitives-colour-surface-default-k2': '#f4f3f7',
    '--primitives-colour-surface-default-k3': '#efeef2',
    '--primitives-colour-surface-default-k4': '#ebeaee',
    '--primitives-colour-surface-tonal-k1': '#fffcfc',
    '--primitives-colour-surface-tonal-k2': '#fff5f6',
    '--primitives-colour-surface-tonal-k3': '#ffeceb',
    '--primitives-colour-surface-tonal-k4': '#fedede',
    '--primitives-colour-decorative-teal-foreground': '#ffffff',
    '--primitives-colour-decorative-teal-surface': '#106f85',
    '--primitives-colour-decorative-blue-foreground': '#ffffff',
    '--primitives-colour-decorative-blue-surface': '#1d6fea',
    '--primitives-colour-decorative-green-foreground': '#ffffff',
    '--primitives-colour-decorative-green-surface': '#12714d',
    '--primitives-colour-decorative-yellow-foreground': '#1b1b1f',
    '--primitives-colour-decorative-yellow-surface': '#ffca3a',
    '--primitives-colour-decorative-coral-foreground': '#ffffff',
    '--primitives-colour-decorative-coral-surface': '#b94018',
    '--primitives-colour-decorative-pink-foreground': '#ffffff',
    '--primitives-colour-decorative-pink-surface': '#c31f83',
    '--primitives-colour-decorative-purple-foreground': '#ffffff',
    '--primitives-colour-decorative-purple-surface': '#8246d4',
    '--primitives-colour-brand-foreground': '#ffffff',
    '--primitives-colour-brand-background': '#ff5447',
    '--radii-none': '0px',
    '--radii-xsmall': '4px',
    '--radii-small': '6px',
    '--radii-medium': '8px',
    '--radii-large': '12px',
    '--radii-xlarge': '16px',
    '--radii-full': '999px',
    '--sizes-xsmall-font-size-display': '0.75rem',
    '--sizes-xsmall-font-size-text': '0.75rem',
    '--sizes-xsmall-border-radius': '4px',
    '--sizes-xsmall-box-size': '24px',
    '--sizes-xsmall-gap': '4px',
    '--sizes-xsmall-padding-x': '8px',
    '--sizes-xsmall-padding-y': '2px',
    '--sizes-small-font-size-display': '0.75rem',
    '--sizes-small-font-size-text': '0.875rem',
    '--sizes-small-border-radius': '6px',
    '--sizes-small-box-size': '32px',
    '--sizes-small-gap': '8px',
    '--sizes-small-padding-x': '12px',
    '--sizes-small-padding-y': '4px',
    '--sizes-medium-font-size-display': '1.125rem',
    '--sizes-medium-font-size-text': '1rem',
    '--sizes-medium-border-radius': '8px',
    '--sizes-medium-box-size': '40px',
    '--sizes-medium-gap': '8px',
    '--sizes-medium-padding-x': '16px',
    '--sizes-medium-padding-y': '8px',
    '--sizing-xxsmall': '16px',
    '--sizing-xsmall': '24px',
    '--sizing-small': '32px',
    '--sizing-base': '40px',
    '--sizing-medium': '48px',
    '--sizing-large': '56px',
    '--sizing-xlarge': '72px',
    '--spacing-none': '0px',
    '--spacing-xxsmall': '2px',
    '--spacing-xsmall': '4px',
    '--spacing-small': '8px',
    '--spacing-medium': '12px',
    '--spacing-large': '16px',
    '--spacing-xlarge': '24px',
    '--spacing-xxlarge': '32px',
    '--typography-font-weight-regular': '400',
    '--typography-font-weight-medium': '500',
    '--typography-font-weight-semibold': '600',
    '--typography-font-weight-bold': '700',
    '--typography-font-weight-heavy': '800',
    '--typography-leading-tighter': '1',
    '--typography-leading-tight': '1.2',
    '--typography-leading-base': '1.4',
    '--typography-leading-loose': '1.6',
    '--typography-leading-looser': '1.8',
 }
} as const;