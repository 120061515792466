export type DataAttributeMap = Record<
  string,
  string | number | boolean | undefined
>;

export type WithDataAttributeProp<T> = T & {
  data?: DataAttributeMap;
};

export const buildDataAttributes = (
  data: DataAttributeMap = {},
  prefix?: string
): DataAttributeMap => {
  const keys = Object.keys(data).filter((key) => {
    return data[key] !== undefined;
  });
  const dataAttributes: DataAttributeMap = {};
  const prefixString = prefix ? `data-${prefix}-` : 'data-';

  for (const key of keys) {
    dataAttributes[`${prefixString}${key}`] = data[key];
  }

  return dataAttributes;
};

export const getTestIdFromDataAttributes = (data: DataAttributeMap = {}) => {
  const keys = Object.keys(data);
  if (!keys.length) {
    return undefined;
  }
  const testId = data['testid'];
  if (testId !== undefined && typeof testId !== 'string') {
    throw Error(`testid: ${testId} must be string`);
  }
  return testId;
};
