/** @jsx jsx */

import { Box } from '@balance-web/box';
import { Button } from '@balance-web/button';
import { jsx } from '@balance-web/core';
import { Heading } from '@balance-web/heading';
import { useTheme } from '@balance-web/theme';
import { makeId, useId } from '@balance-web/utils';
import { Text } from '@balance-web/text';
import { Flex } from '@balance-web/flex';

import type { ModalSize } from './size';
import { useModalWidth } from './size';
import { DialogBase } from './DialogBase';

type Action = {
  action: () => void;
  label: string;
};

type AlertDialogProps = {
  actions: {
    block?: boolean /** make action buttons full width */;
    cancel?: Action;
    confirm: Action & { loading?: boolean };
  };
  id?: string;
  isOpen: boolean;
  children: React.ReactNode;
  title: string;
  tone?: 'critical' | 'primary';
  size?: ModalSize;
  autoFocus?: boolean;
};

export const AlertDialog = ({
  actions,
  isOpen,
  children,
  title,
  id,
  tone = 'primary',
  size = 'small',
  autoFocus = true,
}: AlertDialogProps) => {
  const { cancel, confirm } = actions;
  const theme = useTheme();
  const instanceId = useId(id);
  const headingId = makeId(instanceId, 'heading');

  const modalWidth = useModalWidth(size);

  // call the "cancel" action on `Esc` key press (if it exists), or the "confirm" action if there is no "cancel" action.
  const onClose = () => {
    if (actions.cancel) {
      actions.cancel.action();
    } else {
      actions.confirm.action();
    }
  };

  return (
    <DialogBase
      id={instanceId}
      isOpen={isOpen}
      onClose={onClose}
      width={modalWidth}
      aria-labelledby={headingId}
      elevation="modal"
      autoFocus={autoFocus}
    >
      <Box
        paddingX="xlarge"
        css={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}
      >
        <Box paddingTop="xlarge" paddingBottom="large">
          <Heading id={headingId} level="5">
            {title}
          </Heading>
        </Box>

        <Box paddingY="large">
          <Text color="muted">{children}</Text>
        </Box>

        <div
          css={{
            display: 'flex',
            justifyContent: 'start',
            flexWrap: 'wrap',
            flexDirection: actions.block ? 'column-reverse' : 'row',
            paddingTop: theme.spacing.medium,
            paddingBottom: theme.spacing.xlarge,
            gap: theme.spacing.large,
          }}
        >
          {cancel && (
            <Button
              id={makeId(instanceId, 'cancel')}
              disabled={confirm.loading}
              key={cancel.label}
              label={cancel.label}
              onClick={cancel.action}
              variant="text"
              colorScheme="tertiary"
              size="small"
              block={actions.block}
            />
          )}
          <Flex flex="1" justifyContent="end">
            <Button
              id={makeId(instanceId, 'confirm')}
              css={{
                marginLeft: actions.block ? 0 : theme.spacing.medium,
                marginBottom: actions.block ? theme.spacing.medium : 0,
              }}
              key={confirm.label}
              label={confirm.label}
              loading={confirm.loading}
              onClick={confirm.action}
              colorScheme={tone}
              size="small"
              block={actions.block}
            />
          </Flex>
        </div>
      </Box>
    </DialogBase>
  );
};
