import { useContext, useEffect } from 'react';

import { ElevateContext } from './context';
import { useOnElevate } from './maxElevationHooks';

export const useElevationModifier = () => {
  const context = useContext(ElevateContext);
  return context?.zIndex || 0;
};

export const useElevate = ({ zIndex }: { zIndex: number }) => {
  const elevationModifier = useElevationModifier();
  const newZIndex = zIndex + elevationModifier;
  const { onElevate } = useOnElevate();

  useEffect(() => {
    onElevate(newZIndex);
  }, [newZIndex, onElevate]);

  return { zIndex: newZIndex };
};
