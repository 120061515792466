/** @jsx jsx */
import type { ReactNode } from 'react';
import { Box } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { IconButton } from '@balance-web/button';
import { XIcon } from '@balance-web/icon';
import { Text } from '@balance-web/text';
import { Inline } from '@balance-web/inline';

import { TONE_ICONS } from './icons';
import { useAppBanner } from './Context';

export type AppBannerProps = {
  text: string;
  title?: string;
  tone: 'cautious' | 'critical' | 'informative';
  action?: ReactNode;
  onClose?: () => void;
};

export const AppBanner = ({
  text,
  title,
  tone,
  action,
  onClose,
}: AppBannerProps) => {
  useAppBanner();

  const Icon = TONE_ICONS[tone];

  return (
    <Box width="100%" height={40} background={tone}>
      <Inline alignY="center" height="100%">
        <Inline flex="1" gap="xsmall" align="center">
          <Inline
            alignY="center"
            align="center"
            paddingRight="xsmall"
            height={20}
          >
            <Icon size="small" color={tone || 'informative'} />
          </Inline>
          {title && (
            <Text weight="semibold" size="small">
              {title}
            </Text>
          )}
          <Text size="small">{text}</Text>
          {action}
        </Inline>
        {onClose && (
          <Box paddingRight="small">
            <IconButton
              onClick={onClose}
              label="Dismiss"
              icon={XIcon}
              colorScheme="tertiary"
              size="small"
              variant="text"
            />
          </Box>
        )}
      </Inline>
    </Box>
  );
};
