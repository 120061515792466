/**
 * @desc Truncate text
 * @param {string} text text to truncate
 * @param {number} maxLength maximum character length before truncating text
 * @returns truncated text and add '...' if it's more than maxLength
 */
export const truncateText = (text: string = '', maxLength: number = 29) => {
  const safeMaxLength = maxLength - 3; // 3 is for the 3 dots to be appended
  if (text.length <= safeMaxLength) return text;
  return `${text.substring(0, safeMaxLength)}...`;
};
