/** @jsx jsx */
import React, {
  Fragment,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import throttle from 'lodash.throttle';
import { jsx } from '@balance-web/core';

import { Divider } from './Divider';

export const Body = (props: HTMLAttributes<HTMLDivElement>) => {
  const [scrollStatus, setScrollStatus] = useState<ScrollStatus>('none');
  const ref = useRef<HTMLDivElement>(null);

  // reset the status when children change; the scroll height may have changed
  useEffect(() => {
    const bodyElement = ref.current;

    if (bodyElement) {
      setScrollStatus(getScrollStatus(bodyElement));
    }
  }, [props.children]);

  // listen to scroll and update status
  // NOTE: ideally we'd also do this on `resize`, but they impact is so small that
  // i can't justify binding another listener that will affect performance
  useEffect(() => {
    const bodyElement = ref.current;

    if (bodyElement) {
      const handleScroll = throttle(() => {
        setScrollStatus(getScrollStatus(bodyElement));
      }, 200);

      bodyElement.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        handleScroll.cancel();
        bodyElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  // dividers must be rendered outside the scrollable container so they don't
  // move with it. always render dividers so content doesn't jump around.
  return (
    <Fragment>
      <Divider active={['bottom', 'between'].includes(scrollStatus)} />
      <div
        ref={ref}
        css={{
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
        {...props}
      />
    </Fragment>
  );
};

type ScrollStatus = 'none' | 'top' | 'bottom' | 'between';

const getScrollStatus = (element: HTMLDivElement) => {
  const { clientHeight, scrollHeight } = element;

  if (scrollHeight === clientHeight) {
    return 'none';
  }
  if (element.scrollTop === 0) {
    return 'top';
  }
  if (element.scrollTop === scrollHeight - clientHeight) {
    return 'bottom';
  }

  return 'between';
};
