import React, { ReactNode } from 'react';
import { Divider } from '@balance-web/divider';
import { Stack } from '@balance-web/stack';

export const Footer = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Divider />
      <Stack gap="medium" paddingX="xlarge" paddingY="large">
        {children}
      </Stack>
    </>
  );
};
