import facepaint from 'facepaint';
import { BalanceThemeRaw, useRawTheme } from '@balance-web/theme';

type BreakPoints = BalanceThemeRaw['breakpoints'];
type BreakPoint = keyof BreakPoints;

function formatUnits(value: string | number) {
  if (typeof value === 'number') {
    return `${value}px`;
  }

  return value;
}

/*
  Facepaint lets you write properties as arrays e.g.

  <div css={{ width: [200, 400] }} />

  More here: https://github.com/emotion-js/facepaint
*/
const makeMq = (breakpoints: BreakPoints) => {
  return facepaint(
    Object.values(breakpoints).map((w) => {
      return `@media (min-width: ${formatUnits(w)})`;
    })
  );
};

/*
  A helper for handling string OR array values e.g.

  <Component size="small" />
  VS
  <Component size={['small', 'large']} />
*/
const mapResponsiveProp = <
  Map extends Record<string, string | number>,
  Keys extends keyof Map
>(
  value: Keys | readonly (Keys | null)[],
  valueMap: Map
) => {
  if (Array.isArray(value)) {
    return value.map((k) => (k == null ? null : valueMap[k]));
  }
  // @ts-ignore
  return valueMap[value];
};

// helper if array property declaration isn't appropriate
const makeMinBreak = (breakpoints: BreakPoints) => (
  key: BreakPoint,
  prefix = true
) => {
  const width = breakpoints[key];
  const value = `(min-width: ${formatUnits(width)})`;

  return prefix ? `@media ${formatUnits(value)}` : value;
};

// the breakpoints are designed to go up i.e. min-width
// if a max-width is necessary (hopefully rare) it's nice to provide a helper
const makeMaxBreak = (breakpoints: BreakPoints) => (
  key: BreakPoint,
  prefix = true
) => {
  const width = breakpoints[key];
  const value = `(max-width: ${formatUnits(width - 1)})`;

  return prefix ? `@media ${formatUnits(value)}` : value;
};

// FIXME:
// Should this even be a hook? I think we can just export these utilities...
export const useMediaQuery = () => {
  const rawTheme = useRawTheme();
  return {
    mq: makeMq(rawTheme.breakpoints),
    mapResponsiveProp,
    maxBreak: makeMaxBreak(rawTheme.breakpoints),
    minBreak: makeMinBreak(rawTheme.breakpoints),
  };
};
