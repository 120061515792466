import * as React from 'react';

import { createIcon } from '../Icon';

export const GrabIcon = createIcon(
  <React.Fragment>
    <rect width="1" height="1" x="6" y="4" />
    <rect width="1" height="1" x="6" y="10" />
    <rect width="1" height="1" x="6" y="16" />
    <rect width="1" height="1" x="6" y="22" />
    <rect width="1" height="1" x="12" y="4" />
    <rect width="1" height="1" x="12" y="10" />
    <rect width="1" height="1" x="12" y="16" />
    <rect width="1" height="1" x="12" y="22" />
  </React.Fragment>,
  'GrabIcon'
);
