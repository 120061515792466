// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ReactElement } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children: ReactElement;
};

export const Portal = ({ children }: Props) => {
  if (typeof document === 'undefined') {
    return null;
  }

  return createPortal(children, document.body);
};
