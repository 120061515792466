import React, { HTMLAttributes } from 'react';

type Props = {
  label?: string;
  symbol: string;
} & HTMLAttributes<HTMLSpanElement>;

export const Emoji = ({ label, symbol, ...props }: Props) => (
  <span
    aria-hidden={label ? undefined : true}
    aria-label={label}
    role="img"
    {...props}
  >
    {symbol}
  </span>
);
