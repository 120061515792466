import React from 'react';
import { Heading } from '@balance-web/heading';

import { useSideDrawerBase } from './context';

export const Title = ({ children }: { children: string }) => {
  const { headingId } = useSideDrawerBase();

  return (
    <Heading id={headingId} level="4">
      {children}
    </Heading>
  );
};
