type Obj = Record<string, any>;

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object
    ? T[P] extends Array<infer U>
      ? Array<DeepPartial<U>>
      : T[P] extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : DeepPartial<T[P]>
    : T[P];
};

const isObject = (obj?: Obj) => obj && typeof obj === 'object';

/**
 * Performs a deep merge of `partial` into `source`.
 */
export function mergeDeep<T>(source: T, partial: DeepPartial<T>): T {
  const result: any = { ...source }; // don't mutate `source`, it will have unexpected side-effects

  if (!isObject(result) || !isObject(partial)) {
    return source;
  }

  for (let key in result) {
    const resultValue = result[key];
    const partialValue = (partial as any)[key];

    // NOTE: we (probably?) don't want to concatenate arrays...
    if (
      partialValue &&
      Array.isArray(resultValue) &&
      Array.isArray(partialValue)
    ) {
      result[key] = partialValue;
    } else if (
      partialValue &&
      isObject(resultValue) &&
      isObject(partialValue)
    ) {
      result[key] = mergeDeep(resultValue, partialValue);
    } else if (partialValue) {
      result[key] = partialValue;
    }
  }

  return result;
}
