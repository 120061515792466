import type {
  BalanceThemeRaw,
  BalanceThemeWithPalette,
} from '@balance-web/theme';

export type ColorScheme = 'primary' | 'secondary' | 'tertiary' | 'critical';
export type Variant = 'filled' | 'outline' | 'text';
export type Size = 'small' | 'medium' | 'large';

export type ButtonStylesProps = {
  colorScheme: ColorScheme;
  variant: Variant;
  borderRadius?: number | string;
  fontWeight?: number;
  disabled?: boolean;
  loading?: boolean;
  block: boolean;
  hasIcon: boolean;
  isIcon: boolean;
  size: Size;
};

export const getButtonStyles = (
  {
    colorScheme,
    variant,
    block = false,
    fontWeight,
    size,
    disabled,
    hasIcon,
    isIcon,
  }: ButtonStylesProps,
  theme: BalanceThemeWithPalette,
  rawTheme: BalanceThemeRaw
) => {
  let paddingBlock = '0';
  let paddingInline = '0';

  // Annoying tiny adjustments because padding multiples + font size is always off by a few pixel.
  // Can't hardcode height because OS font size can become inaccessible.
  if (isIcon) {
    if (size === 'small') {
      paddingBlock = theme.spacing.small;
      paddingInline = theme.spacing.small;
    } else if (size === 'medium') {
      paddingBlock = theme.spacing.small;
      paddingInline = theme.spacing.small;
    } else if (size === 'large') {
      paddingBlock = theme.spacing.medium;
      paddingInline = theme.spacing.medium;
    }
  } else {
    paddingInline = theme.spacing[size === 'large' ? 'xlarge' : 'large'];

    if (hasIcon && size === 'small') {
      paddingBlock = theme.spacing.small;
    } else if (hasIcon && size === 'medium') {
      paddingBlock = theme.spacing.small;
    } else if (hasIcon && size === 'large') {
      paddingBlock = theme.spacing.small;
    } else if (size === 'small') {
      paddingBlock = `calc(${theme.spacing.small} + 1px)`;
    } else if (size === 'medium') {
      paddingBlock = theme.spacing.medium;
    } else if (size === 'large') {
      paddingBlock = `calc(${theme.spacing.large} - 1px)`;
    }
  }

  const baseStyles = {
    position: 'relative',
    alignItems: 'center',
    backgroundColor: 'white',
    border: 0,
    borderRadius: theme.radii.full,
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: block ? 'flex' : 'inline-flex',
    gap: theme.spacing.small,
    flexShrink: 0, // button text should NOT wrap, even within a flex container
    fontFamily: theme.typography.fontFamily.body,
    fontWeight,
    justifyContent: 'center',
    outline: 0,
    textDecoration: 'none',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    width: block ? '100%' : undefined,

    // size properties
    fontSize: theme.typography.fontSize[size],
    paddingInline,
    paddingBlock,

    // cover all cases by desaturating and lowering opacity
    '&[aria-disabled=true]': {
      cursor: 'default',
    },

    '&:not([aria-disabled=true]).focus-visible': {
      boxShadow: `0 0 0 2px ${theme.palette.global.focusRing}`,
    },
  };

  const colors = {
    primary: {
      filled: {
        background: theme.primitives.colour.primary.k40,
        border: theme.primitives.colour.primary.k40,
        text: theme.primitives.colour.primary.k100,
        hover: {
          background:
            theme.primitives.colour.primary.interactions.default.light,
        },
        focused: {
          background: theme.primitives.colour.primary.interactions.default.mid,
        },
        pressed: {
          background: theme.primitives.colour.primary.interactions.default.high,
        },
      },
      outline: {
        background: theme.primitives.colour.primary.k100,
        border: theme.primitives.colour.primary.k40,
        text: theme.primitives.colour.primary.k40,
        hover: {
          background:
            theme.primitives.colour.primary.interactions.inverse.light,
        },
        focused: {
          background: theme.primitives.colour.primary.interactions.inverse.mid,
        },
        pressed: {
          background: theme.primitives.colour.primary.interactions.inverse.high,
        },
      },
      text: {
        background: 'none',
        border: undefined,
        text: theme.primitives.colour.primary.k40,
        hover: {
          background:
            theme.primitives.colour.primary.interactions.inverse.light,
        },
        focused: {
          background: theme.primitives.colour.primary.interactions.inverse.mid,
        },
        pressed: {
          background: theme.primitives.colour.primary.interactions.inverse.high,
        },
      },
    },
    secondary: {
      filled: {
        background: theme.primitives.colour.secondary.k40,
        border: theme.primitives.colour.secondary.k40,
        text: theme.primitives.colour.secondary.k100,
        hover: {
          background:
            theme.primitives.colour.secondary.interactions.default.light,
        },
        focused: {
          background:
            theme.primitives.colour.secondary.interactions.default.mid,
        },
        pressed: {
          background:
            theme.primitives.colour.secondary.interactions.default.high,
        },
      },
      outline: {
        background: theme.primitives.colour.secondary.k100,
        border: theme.primitives.colour.secondary.k40,
        text: theme.primitives.colour.secondary.k40,
        hover: {
          background:
            theme.primitives.colour.secondary.interactions.inverse.light,
        },
        focused: {
          background:
            theme.primitives.colour.secondary.interactions.inverse.mid,
        },
        pressed: {
          background:
            theme.primitives.colour.secondary.interactions.inverse.high,
        },
      },
      text: {
        background: 'none',
        border: undefined,
        text: theme.primitives.colour.secondary.k40,
        hover: {
          background:
            theme.primitives.colour.secondary.interactions.inverse.light,
        },
        focused: {
          background:
            theme.primitives.colour.secondary.interactions.inverse.mid,
        },
        pressed: {
          background:
            theme.primitives.colour.secondary.interactions.inverse.high,
        },
      },
    },
    tertiary: {
      filled: {
        background: theme.primitives.colour.neutral.k98,
        border: theme.primitives.colour.neutral.k90,
        text: theme.primitives.colour.neutral.k40,
        hover: {
          background:
            theme.primitives.colour.neutral.interactions.container.light,
        },
        focused: {
          background:
            theme.primitives.colour.neutral.interactions.container.mid,
        },
        pressed: {
          background:
            theme.primitives.colour.neutral.interactions.container.high,
        },
      },
      outline: {
        background: theme.primitives.colour.neutral.k100,
        border: theme.primitives.colour.neutral.k90,
        text: theme.primitives.colour.neutral.k40,
        hover: {
          background:
            theme.primitives.colour.neutral.interactions.container.light,
        },
        focused: {
          background:
            theme.primitives.colour.neutral.interactions.container.mid,
        },
        pressed: {
          background:
            theme.primitives.colour.neutral.interactions.container.high,
        },
      },
      text: {
        background: 'none',
        border: undefined,
        text: theme.primitives.colour.neutral.k40,
        hover: {
          background:
            theme.primitives.colour.neutral.interactions.container.light,
        },
        focused: {
          background:
            theme.primitives.colour.neutral.interactions.container.mid,
        },
        pressed: {
          background:
            theme.primitives.colour.neutral.interactions.container.high,
        },
      },
    },
    critical: {
      filled: {
        background: theme.primitives.colour.reserved.critical.k40,
        border: theme.primitives.colour.reserved.critical.k40,
        text: theme.primitives.colour.reserved.critical.k100,
        hover: {
          background:
            theme.primitives.colour.reserved.critical.interactions.default
              .light,
        },
        focused: {
          background:
            theme.primitives.colour.reserved.critical.interactions.default.mid,
        },
        pressed: {
          background:
            theme.primitives.colour.reserved.critical.interactions.default.high,
        },
      },
      outline: {
        background: theme.primitives.colour.reserved.critical.k100,
        border: theme.primitives.colour.reserved.critical.k40,
        text: theme.primitives.colour.reserved.critical.k40,
        hover: {
          background:
            theme.primitives.colour.reserved.critical.interactions.inverse
              .light,
        },
        focused: {
          background:
            theme.primitives.colour.reserved.critical.interactions.inverse.mid,
        },
        pressed: {
          background:
            theme.primitives.colour.reserved.critical.interactions.inverse.high,
        },
      },
      text: {
        background: 'none',
        border: undefined,
        text: theme.primitives.colour.reserved.critical.k40,
        hover: {
          background:
            theme.primitives.colour.reserved.critical.interactions.inverse
              .light,
        },
        focused: {
          background:
            theme.primitives.colour.reserved.critical.interactions.inverse.mid,
        },
        pressed: {
          background:
            theme.primitives.colour.reserved.critical.interactions.inverse.high,
        },
      },
    },
    disabled: {
      filled: {
        background: theme.primitives.colour.neutral.interactions.inverse.light,
        border: theme.primitives.colour.neutral.interactions.inverse.light,
        text: theme.primitives.colour.neutral.k80,
      },
      outline: {
        background: theme.primitives.colour.neutral.interactions.inverse.light,
        border: theme.primitives.colour.neutral.interactions.inverse.light,
        text: theme.primitives.colour.neutral.k80,
      },
      text: {
        background: 'none',
        border: undefined,
        text: theme.primitives.colour.neutral.k80,
      },
    },
  };

  return {
    root: {
      ...baseStyles,
      background: disabled
        ? colors.disabled[variant].background
        : colors[colorScheme][variant].background,
      color: disabled
        ? colors.disabled[variant].text
        : colors[colorScheme][variant].text,
      boxShadow: disabled
        ? colors.disabled[variant].border
          ? `inset 0 0 0 1px ${colors.disabled[variant].border}`
          : undefined
        : colors[colorScheme][variant].border
        ? `inset 0 0 0 1px ${colors[colorScheme][variant].border}`
        : undefined,
      '&:not([aria-disabled=true])': {
        '&:hover': {
          '> .hover-overlay': {
            opacity: 1,
            pointerEvents: 'all',
          },
        },
        '&:focus': {
          '> .focused-overlay': {
            opacity: 1,
            pointerEvents: 'all',
          },
          '> .hover-overlay': {
            opacity: 0,
            pointerEvents: 'none',
          },
        },
        '&:active': {
          transform: 'translateY(1px)',
          '> .pressed-overlay': {
            opacity: 1,
            pointerEvents: 'all',
          },
          '> .hover-overlay': {
            opacity: 0,
            pointerEvents: 'none',
          },
          '> .focused-overlay': {
            opacity: 0,
            pointerEvents: 'none',
          },
        },
      },
    },
    hoverOverlay: {
      background: colors[colorScheme][variant].hover.background,
      transition: 'opacity 200ms linear',
    },
    pressedOverlay: {
      background: colors[colorScheme][variant].pressed.background,
      transition: 'opacity 200ms linear',
    },
    focusedOverlay: {
      background: colors[colorScheme][variant].focused.background,
      transition: 'opacity 200ms linear',
    },
  };
};
