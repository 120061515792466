/** @jsx jsx */

import { forwardRef, useRef } from 'react';
import type {
  ButtonHTMLAttributes,
  InputHTMLAttributes,
  KeyboardEvent,
} from 'react';
import { jsx } from '@balance-web/core';
import { buildDataAttributes } from '@balance-web/core';
import type { WithDataAttributeProp } from '@balance-web/core';
import { useFieldContext } from '@balance-web/field';
import { SearchIcon } from '@balance-web/icon/icons/SearchIcon';
import { XIcon } from '@balance-web/icon/icons/XIcon';
import {
  Adornment,
  AdornmentWrapper,
  useInputStyles,
} from '@balance-web/text-input';
import { useTheme } from '@balance-web/theme';
import { useForkedRef, wrapHandlers } from '@balance-web/utils';

const noop = () => {};

type InputProps = InputHTMLAttributes<HTMLInputElement>;

export type SearchInputProps = WithDataAttributeProp<
  {
    size?: 'small' | 'medium';
    onChange: NonNullable<InputProps['onChange']>;
    onClear: () => void;
    value: NonNullable<InputProps['value']>;
  } & Omit<InputProps, 'onChange' | 'type' | 'size' | 'type' | 'value'>
>;

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (
    { onClear = noop, size = 'medium' as const, data, ...consumerProps },
    consumerRef
  ) => {
    const { sizing } = useTheme();
    const internalRef = useRef<HTMLInputElement>();
    const { invalid, ...a11yProps } = useFieldContext();

    const boxSize = size === 'small' ? sizing.small : sizing.base;

    const baseStyles = useInputStyles({
      size,
      shape: 'round',
    });
    const insetStyles = {
      ...baseStyles,
      paddingLeft: boxSize,
      paddingRight: boxSize,

      /* remove the 'X' from Internet Explorer */
      '&::-ms-clear, &::-ms-reveal': {
        display: 'none',
        width: 0,
        height: 0,
      },

      /* remove the 'X' from Chrome */
      '&::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration': {
        display: 'none',
      },
    };

    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        event.preventDefault(); // Avoid potential drawer close
        onClear();
      }
    }
    const handleClear = wrapHandlers(onClear, () => {
      internalRef.current?.focus();
    });

    const combindedRef = useForkedRef(internalRef, consumerRef);

    const dataAttributes = buildDataAttributes(data);

    return (
      <AdornmentWrapper size={size} shape="round">
        <SearchElement />
        <input
          aria-invalid={invalid}
          ref={combindedRef}
          type="search"
          css={insetStyles}
          onKeyDown={handleKeyDown}
          {...dataAttributes}
          {...a11yProps}
          {...consumerProps}
        />
        {consumerProps.value && <ClearElement onClick={handleClear} />}
      </AdornmentWrapper>
    );
  }
);

// Styled Components
// ------------------------------

const SearchElement = () => {
  const { palette } = useTheme();

  return (
    <Adornment
      align="left"
      css={{
        color: palette.text.dim,
        pointerEvents: 'none',
      }}
    >
      <SearchIcon size="small" />
    </Adornment>
  );
};
const ClearElement = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { palette } = useTheme();

  return (
    <Adornment
      as="button"
      align="right"
      tabIndex={-1}
      css={{
        alignItems: 'center',
        justifyContent: 'center',
        background: 0,
        border: 0,
        borderRadius: '50%',
        color: palette.text.dim,
        display: 'flex',
        padding: 0,
        outline: 0,

        // No focus styles because this button is not focusable.
        // Keyboard interaction is handled by "Escape" press while focused
        ':hover': {
          color: palette.text.muted,
        },
      }}
      {...props}
    >
      <XIcon size="small" />
    </Adornment>
  );
};
