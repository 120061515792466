import { useEffect } from 'react';

// NOTE: mouse event handler defined here rather than imported from react becase
// the event listener will return a native event, not a synthetic event
type MouseHandler = (event: MouseEvent) => void;
type UseClickOutsideProps = {
  handler: MouseHandler;
  elements: (HTMLElement | undefined | null)[];
  listenWhen: boolean;
};

export const useClickOutside = ({
  handler,
  elements,
  listenWhen,
}: UseClickOutsideProps) => {
  useEffect(() => {
    if (listenWhen) {
      let handleMouseDown = (event: MouseEvent) => {
        // bail on mouse down "inside" any of the provided elements
        if (elements.some((el) => el && el.contains(event.target as Node))) {
          return;
        }

        handler(event);
      };
      document.addEventListener('mousedown', handleMouseDown);

      return () => {
        document.removeEventListener('mousedown', handleMouseDown);
      };
    }
  }, [handler, elements, listenWhen]);
};
