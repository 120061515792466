/** @jsx jsx */
import type { ReactElement, ReactNode } from 'react';
import { Button } from '@balance-web/button';
import { jsx } from '@balance-web/core';
import { Heading } from '@balance-web/heading';
import { Inline } from '@balance-web/inline';
import { Stack } from '@balance-web/stack';
import { forwardRefWithAs, makeId, useId } from '@balance-web/utils';
import { Text } from '@balance-web/text';

import type { ModalSize } from './size';
import { useModalWidth } from './size';
import { DialogBase } from './DialogBase';

type Action = {
  action: () => void;
  label: string;
};

type InfoDialogProps = {
  actions: {
    cancel?: Action;
    confirm: Action & { loading?: boolean };
  };
  /** Use any of our spot or hero illustrations here. */
  illustration: ReactElement;
  children: ReactNode;
  title: string;
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  size?: ModalSize;
};

export const InfoDialog = forwardRefWithAs<'div', InfoDialogProps>(
  (
    {
      actions,
      children,
      illustration,
      title,
      id,
      isOpen,
      onClose,
      size = 'small',
      ...props
    },
    ref
  ) => {
    const { cancel, confirm } = actions;
    const instanceId = useId(id);
    const headingId = makeId(instanceId, 'heading');

    const modalWidth = useModalWidth(size);

    return (
      <DialogBase
        id={instanceId}
        isOpen={isOpen}
        onClose={onClose}
        width={modalWidth}
        ref={ref}
        aria-labelledby={headingId}
        autoFocus={false}
        {...props}
      >
        <Stack
          gap="xxlarge"
          align="center"
          padding="xlarge"
          css={{
            textAlign: 'center',
          }}
        >
          {illustration}
          <Stack gap="medium">
            <Heading level="4" as="h2" id={headingId}>
              {title}
            </Heading>
            <Text color="muted">{children}</Text>
          </Stack>

          <Inline gap="large">
            {cancel && (
              <Button
                id={makeId(instanceId, 'cancel')}
                disabled={confirm.loading}
                key={cancel.label}
                label={cancel.label}
                onClick={cancel.action}
                variant="text"
                colorScheme="tertiary"
                size="small"
              />
            )}
            <Button
              id={makeId(instanceId, 'confirm')}
              key={confirm.label}
              label={confirm.label}
              loading={confirm.loading}
              onClick={confirm.action}
              size="small"
            />
          </Inline>
        </Stack>
      </DialogBase>
    );
  }
);
