import React, { ReactNode, useContext } from 'react';
import { Transition } from 'react-transition-group';

import { SideDrawerControllerContextType, TransitionState } from './types';

type SideDrawerControllerProps = {
  isOpen?: boolean;
  onClose: () => void;
  children: ReactNode;
};

const SideDrawerControllerContext = React.createContext<null | SideDrawerControllerContextType>(
  {
    onClose: () => {},
    transitionState: 'exited',
  }
);

export const SideDrawerControllerContextProvider =
  SideDrawerControllerContext.Provider;

export const useSideDrawerControllerContext = () => {
  let context = useContext(SideDrawerControllerContext);
  if (!context) {
    throw new Error(
      'SideDrawer must be wrapped in a <SideDrawerController>. You should generally do this outside of the component that renders the <SideDrawer>. See https://balance.reckon.com/package/side-drawer#sidedrawercontroller for how to use it and why.'
    );
  }

  return context;
};

export const SideDrawerController = ({
  isOpen,
  onClose,
  children,
}: SideDrawerControllerProps) => {
  return (
    <Transition appear mountOnEnter unmountOnExit in={isOpen} timeout={150}>
      {(transitionState: TransitionState) => (
        <SideDrawerControllerContextProvider
          value={{
            onClose,
            transitionState,
          }}
        >
          {children}
        </SideDrawerControllerContextProvider>
      )}
    </Transition>
  );
};
