import React, { FormEventHandler, ReactNode } from 'react';

type FormProps = {
  children: ReactNode;
  onSubmit: FormEventHandler<HTMLFormElement>;
};

export const Form = ({ children, onSubmit }: FormProps) => {
  return (
    <form onSubmit={onSubmit} style={{ display: 'contents' }}>
      {children}
    </form>
  );
};
