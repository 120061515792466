import React from 'react';
import type { ReactNode } from 'react';
import { DrawerProvider } from '@balance-web/drawer';
import { ToastProvider } from '@balance-web/toast';
import { SideDrawerProvider } from '@balance-web/side-drawer';
import { BannerProvider } from '@balance-web/banner';

export const PackageProviders = ({ children }: { children?: ReactNode }) => {
  return (
    <ToastProvider>
      <SideDrawerProvider>
        <BannerProvider>
          <DrawerProvider>{children}</DrawerProvider>
        </BannerProvider>
      </SideDrawerProvider>
    </ToastProvider>
  );
};
