import * as React from 'react';

import { createIcon } from '../Icon';
export const ChevronsUpIcon = createIcon(
  <React.Fragment>
    <polyline points="17 11 12 6 7 11" />
    <polyline points="17 18 12 13 7 18" />
  </React.Fragment>,
  'ChevronsUpIcon'
);
