import React, {
  AnchorHTMLAttributes,
  ComponentType,
  RefAttributes,
  createContext,
  forwardRef,
  useContext,
} from 'react';

export type LinkComponentProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  RefAttributes<HTMLAnchorElement> & {
    href: string;
  };

export type LinkComponent = ComponentType<LinkComponentProps>;

export const DefaultLinkComponent = forwardRef<
  HTMLAnchorElement,
  LinkComponentProps
>((props, ref) => <a ref={ref} {...props} />);

export const LinkComponentContext = createContext<LinkComponent>(
  DefaultLinkComponent
);

export const useLinkComponent = () => {
  const linkComponent = useContext(LinkComponentContext);

  return linkComponent;
};
