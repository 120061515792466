/** @jsx jsx */

import type { MutableRefObject, ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { makeId, useId } from '@balance-web/utils';
import type { WithDataAttributeProp } from '@balance-web/core';
import { buildDataAttributes } from '@balance-web/core';
import { Elevate } from '@balance-web/elevate';

import { useSideDrawerManager } from './context';
import { SideDrawerBase } from './SideDrawerBase';
import { useSideDrawerControllerContext } from './SideDrawerController';
import { Title } from './Title';
import { Header } from './Header';
import { Footer } from './Footer';
import { Body } from './Body';
import { Form } from './Form';

const easing = 'cubic-bezier(0.2, 0, 0, 1)';

export type SideDrawerProps = WithDataAttributeProp<{
  children: ReactNode;
  id?: string;
  'aria-label'?: string;
  'aria-labelledby'?: string;
  initialFocusRef?: MutableRefObject<any>;
  background?: 'base' | 'muted';
}>;

export const SideDrawer = ({
  id,
  children,
  initialFocusRef,
  data,
  ...props
}: SideDrawerProps) => {
  const { transitionState } = useSideDrawerControllerContext();

  const theme = useTheme();
  const instanceId = useId(id) || '1';

  const uniqueKey = makeId('side-drawer', instanceId);
  // sync drawer state
  const drawerDepth = useSideDrawerManager(uniqueKey);

  const dialogTransition = getDialogTransition(drawerDepth);
  const transitionStyles = dialogTransition[transitionState];

  return (
    <SideDrawerBase
      id={instanceId}
      transitionState={transitionState}
      initialFocusRef={initialFocusRef}
      {...(data ? buildDataAttributes(data) : undefined)}
      {...props}
    >
      {({ Tag, css, ...props }) => (
        <Tag
          {...props}
          style={transitionStyles}
          css={{
            ...css,
            borderRadius: theme.radii.large,
            bottom: theme.spacing.small,
            boxShadow: theme.shadow.large,
            position: 'fixed',
            right: theme.spacing.small,
            top: theme.spacing.small,
            transition: `transform 150ms ${easing}`,
            width: 448,
            zIndex: theme.elevation.modal,

            // flex layout must be applied here so content will grow/shrink properly
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Elevate>{children}</Elevate>
        </Tag>
      )}
    </SideDrawerBase>
  );
};

function getDialogTransition(depth: number) {
  let scaleInc = 0.05;
  let transformValue = `scale(${1 - scaleInc * depth}) translateX(-${
    depth * 40
  }px)`;

  return {
    entering: { transform: 'translateX(100%)' },
    entered: { transform: transformValue },
    exiting: { transform: 'translateX(100%)' },
    exited: { transform: 'translateX(100%)' },
  };
}

// Primitives
SideDrawer.Header = Header;
SideDrawer.Title = Title;
SideDrawer.Body = Body;
SideDrawer.Form = Form;
SideDrawer.Footer = Footer;
