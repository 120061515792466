/** @jsx jsx */

import { ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { BalanceTheme } from '@balance-web/theme';
import { forwardRefWithAs } from '@balance-web/utils';

import { useMediaQuery } from './useMediaQuery';

type Breakpoint = keyof BalanceTheme['breakpoints'];
type MediaQueryProps = {
  above?: Breakpoint;
  below?: Breakpoint;
  children: ReactNode;
  visible: boolean;
};

const MediaQuery = forwardRefWithAs<'div', MediaQueryProps>(
  ({ as: Tag = 'div', above, below, visible, children, ...props }, ref) => {
    const queryString = useQueryString(above, below);

    if (!queryString) return null;

    return (
      <Tag
        css={{
          display: visible ? 'none' : 'initial',

          [queryString]: {
            display: visible ? 'initial' : 'none',
          },
        }}
        ref={ref}
        {...props}
      >
        {children}
      </Tag>
    );
  }
);

const useQueryString = (above?: Breakpoint, below?: Breakpoint) => {
  const { minBreak, maxBreak } = useMediaQuery();

  if (!above && !below) {
    throw Error('You must provide a value for either `above` or `below`.');
  }

  if (above && below) {
    return `@media ${minBreak(above, false)} and ${maxBreak(below, false)}`;
  }

  if (above) {
    return minBreak(above);
  }

  if (below) {
    return maxBreak(below);
  }
};

type VisibleOrHiddenProps = Omit<MediaQueryProps, 'visible'>;

export const Visible = forwardRefWithAs<'div', VisibleOrHiddenProps>(
  ({ children, ...props }, ref) => {
    return (
      <MediaQuery {...props} visible={true} ref={ref}>
        {children}
      </MediaQuery>
    );
  }
);

export const Hidden = forwardRefWithAs<'div', VisibleOrHiddenProps>(
  ({ children, ...props }, ref) => {
    return (
      <MediaQuery {...props} visible={false} ref={ref}>
        {children}
      </MediaQuery>
    );
  }
);
