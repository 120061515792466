// Because the button components are polymorphic (from the "as" prop) it's
// difficult to statically type handlers and optional props. We know what we're
// doing here so we'll ignore TS warnings.

/**
 * Prevent click events when the component is "disabled". Note that we don't
 * want to actually disable a button element because that would prohibit the use
 * of tooltips.
 */
export function usePreventableClickHandler(props: any, disabled: boolean) {
  return function handleClick(event: any) {
    if (disabled) {
      event.preventDefault();
    } else if ('onClick' in props) {
      // @ts-ignore
      props.onClick(event);
    }
  };
}
