import React from 'react';
import type { ReactNode } from 'react';
import { Box } from '@balance-web/box';
import { Inline } from '@balance-web/inline';
import { Text } from '@balance-web/text';

import { TONE_ICONS } from './icons';
import { useGlobalBanner } from './Context';
export type GlobalBannerProps = {
  text: string;
  title?: string;
  tone?: 'cautious' | 'critical' | 'informative';
  startElement?: ReactNode;
  action?: ReactNode;
};

export const GlobalBanner = ({
  text,
  title,
  tone,
  startElement,
  action,
}: GlobalBannerProps) => {
  useGlobalBanner();

  const Icon = tone ? TONE_ICONS[tone] : null;

  return (
    <Box height={56} width="100%" background="muted">
      <Inline height="100%" gap="xsmall" align="center" alignY="center">
        {startElement
          ? startElement
          : Icon &&
            tone && (
              <Box paddingRight="xsmall">
                <Icon color={tone} />
              </Box>
            )}
        {title && (
          <Text weight="semibold" size="small">
            {title}
          </Text>
        )}
        <Text size="small">{text}</Text>
        {action}
      </Inline>
    </Box>
  );
};
