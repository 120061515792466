import React, { createContext, useState } from 'react';
import type { ReactNode } from 'react';

type ElevationContextType = {
  zIndex: number;
  onElevate: (elevation: number) => void;
};

export const MaxElevationContext = createContext<ElevationContextType | null>(
  null
);

/**
 * This provider should be used above the ThemeProvider and hence you can't use theming in this component.
 * Handles elevation for components that are rendered once near the top of the React tree and show above everything else such as Toast.
 */
export const MaxElevationProvider = ({ children }: { children: ReactNode }) => {
  const [zIndex, setZIndex] = useState(0);

  const onElevate = (elevation: number) => {
    setZIndex((zIndex) => Math.max(zIndex, elevation));
  };

  return (
    <MaxElevationContext.Provider value={{ zIndex, onElevate }}>
      {children}
    </MaxElevationContext.Provider>
  );
};
