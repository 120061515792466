/** @jsx jsx */

import { forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import { Flex, FlexProps } from '@balance-web/flex';
import { Text } from '@balance-web/text';
import { useTheme } from '@balance-web/theme';
import { DisallowStyleProps, getContrastText } from '@balance-web/utils';

import { UseToneColorProps, useToneColor } from './useToneColor';

type ValidBoxProps = Pick<FlexProps, 'data' | 'id'>;
export type NotificationBadgeProps = {
  /**
   * Optionally cap the value of a badge. When the `value` is greater than the
   * `max` prop, a "+"" will automatically be appended.
   */
  max?: number;
  /** The tone of the badge. */
  tone?: UseToneColorProps['tone'];
  /** The number displayed inside the badge. */
  value: number;
} & DisallowStyleProps &
  ValidBoxProps;

/** A visual indicator for numeric values such as tallies and scores. */
export const NotificationBadge = forwardRef<
  HTMLDivElement,
  NotificationBadgeProps
>(({ data, id, max, tone, value }, forwardedRef) => {
  const { sizing } = useTheme();
  const backgroundColor = useToneColor({ tone });
  const color = getContrastText(backgroundColor);
  const height = sizing.xsmall; // FIXME: should be resolved by the `Box` component

  return (
    <Flex
      data={data}
      id={id}
      ref={forwardedRef}
      // styles
      alignItems="center"
      borderRadius="full"
      inline
      paddingX="small"
      css={{ backgroundColor, height }}
    >
      <Text
        size="xsmall"
        overflowStrategy="nowrap"
        weight="bold"
        tabularNumbers
        css={{ color, lineHeight: 1 }}
      >
        {getBadgeLabel(value, max)}
      </Text>
    </Flex>
  );
});

// Utils
// ------------------------------

function getBadgeLabel(value: number, max?: number) {
  if (max && value >= max) return `${max}+`;

  return value.toString();
}
