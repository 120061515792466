import { useTheme } from '@balance-web/theme';

const toneToForegroundKey = {
  accent: 'accent',
  neutral: 'muted',
  cautious: 'cautious',
  critical: 'critical',
  informative: 'informative',
  positive: 'positive',
} as const;

export type UseToneColorProps = {
  tone?: keyof typeof toneToForegroundKey;
};

export function useToneColor({ tone = 'neutral' }: UseToneColorProps) {
  const { palette } = useTheme();
  return palette.text[toneToForegroundKey[tone]];
}
