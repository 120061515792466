/** @jsx jsx */
import { ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { Inline } from '@balance-web/inline';
import { useTheme } from '@balance-web/theme';

export type HeaderProps = {
  children: ReactNode;
};

export const Header = ({ children }: HeaderProps) => {
  const { borderWidth, palette } = useTheme();
  return (
    <Inline
      alignY="center"
      paddingX="large"
      paddingY="medium"
      css={{
        borderBottom: `${borderWidth.standard} solid ${palette.global.border}`,
      }}
    >
      {children}
    </Inline>
  );
};
