/** @jsx jsx */

import type { FC } from 'react';
import type { ResponsiveProp } from '@balance-web/core';
import { jsx } from '@balance-web/core';
import { useMediaQuery } from '@balance-web/media-query';
import { useTheme } from '@balance-web/theme';
import type { BalanceTheme } from '@balance-web/theme';

// TODO: colors are actually tones now. this must be updated when folks stop working on application code

export type IconProps = {
  /** The color key for the SVG fill property. */
  color?: keyof BalanceTheme['palette']['text'] | 'inputDisabled';
  /** The size key for the icon. */
  size?: ResponsiveProp<keyof typeof sizeMap> | number;
};

type IconOptions = {
  fill?: boolean;
  stroke?: boolean;
};

// TODO: Move to theme?
const sizeMap = {
  xsmall: 12,
  small: 16,
  medium: 24,
  large: 32,
};

export const createIcon = (
  children: React.ReactNode,
  name: string,
  options?: IconOptions
) => {
  const defaultOptions: IconOptions = {
    fill: false,
    stroke: true,
    ...options,
  };
  let Icon: FC<IconProps> = ({
    size = 'medium',
    color: colorKey,
    ...props
  }) => {
    const { palette } = useTheme();
    const { mq, mapResponsiveProp } = useMediaQuery();
    const resolvedSize =
      typeof size === 'number' ? size : mapResponsiveProp(size, sizeMap);
    const color = colorKey
      ? colorKey === 'inputDisabled'
        ? palette.formInput.textDisabled
        : palette.text[colorKey]
      : 'currentColor';

    return (
      <svg
        aria-hidden="true"
        focusable="false"
        css={mq({
          verticalAlign: 'text-bottom', // removes whitespace inside buttons
          fill: defaultOptions?.fill ? color : 'none',
          stroke: defaultOptions.stroke ? color : 'none',
          strokeLinejoin: 'round',
          strokeLinecap: 'round',
          strokeWidth: 2,
          height: resolvedSize,
          width: resolvedSize,
        })}
        role="img"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        {children}
      </svg>
    );
  };

  Icon.displayName = name;

  return Icon;
};
