/* eslint-disable jsx-a11y/accessible-emoji */
/** @jsx jsx */

import type { ComponentType, HTMLAttributes, ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { Flex } from '@balance-web/flex';
import type { IconProps } from '@balance-web/icon';
import { useTheme } from '@balance-web/theme';
import { forwardRefWithAs } from '@balance-web/utils';
import { buildDataAttributes } from '@balance-web/core';
import type { WithDataAttributeProp } from '@balance-web/core';

import { usePreventableClickHandler } from './utils';

export type ActionButtonProps = WithDataAttributeProp<
  {
    /** Provide an alternate type if the button is within a form. */
    type?: 'submit' | 'button' | 'reset';
    /** The weight of the button. */
    weight?: 'bold' | 'subtle';
    /** Whether the button should be disabled or not */
    disabled?: boolean;
  } & (
    | {
        /** Provide arbitrary element(s) to the button. The `icon*` and `label` properties will no-longer apply. */
        children: ReactNode;
        /** Avoid incompatible types */
        label?: never;
        iconAfter?: never;
        iconBefore?: never;
      }
    | {
        /** The label of the button. */
        label: string;
        /** An icon or element rendered after the button label. */
        iconAfter?: ComponentType<IconProps>;
        /** An icon or element rendered before the button label. */
        iconBefore?: ComponentType<IconProps>;
        /** Avoid incompatible types */
        children?: never;
      }
  ) &
    HTMLAttributes<HTMLButtonElement>
>;

const stroke = (clr: string) => {
  return `inset 0 0 0 1px ${clr}`;
};

export const ActionButton = forwardRefWithAs<'button', ActionButtonProps>(
  (props, ref) => {
    const {
      as: Tag = 'button',
      disabled = false,
      iconAfter: IconAfter,
      iconBefore: IconBefore,
      weight = 'bold',
      data,
      ...restProps
    } = props;

    const { palette, typography, radii, sizing, spacing } = useTheme();

    if (Tag === 'button') {
      restProps.type = restProps.type || 'button';
    }

    const resolvedChildren =
      'children' in props ? (
        props.children
      ) : (
        <Flex alignItems="center" gap="small">
          {IconBefore && <IconBefore size="small" />}
          <span>{props.label}</span>
          {IconAfter && <IconAfter size="small" />}
        </Flex>
      );

    // handle "disabled" behaviour w/o disabling buttons
    const handleClick = usePreventableClickHandler(props, disabled);

    const dataAttributes = buildDataAttributes(data);

    return (
      <Tag
        ref={ref}
        aria-disabled={disabled}
        css={{
          alignItems: 'center',
          appearance: 'none',
          background:
            weight === 'bold' ? palette.actionButton.background : 'transparent',
          border: 0,
          boxShadow:
            weight === 'bold' ? stroke(palette.actionButton.border) : null, // use box-shadow over border to avoid render artifacts
          borderRadius: radii.small,
          boxSizing: 'border-box',
          color: palette.actionButton.text,
          cursor: 'pointer',
          display: 'inline-flex',
          fontFamily: typography.fontFamily.body,
          fontSize: typography.fontSize.small,
          fontWeight: typography.fontWeight.medium,
          height: sizing.small,
          justifyContent: 'center',
          outline: 0,
          padding: `0px ${spacing.medium}`,
          textDecoration: 'none',
          whiteSpace: 'nowrap',

          // disabled styles
          '&[aria-disabled=true]': {
            background: 0,
            color: palette.actionButton.text,
            cursor: 'default',
            opacity: 0.5,
          },
          '&:not([aria-disabled=true]).focus-visible': {
            boxShadow: `0 0 0 2px ${palette.global.focusRing}`,
          },

          // enabled styles
          '&:not([aria-disabled=true])': {
            ':hover': {
              boxShadow:
                weight === 'bold'
                  ? stroke(palette.actionButton.borderFocused)
                  : null,
              backgroundColor: palette.actionButton.backgroundFocused,
              color: palette.actionButton.textFocused,
            },
            ':active': {
              backgroundColor: palette.actionButton.backgroundPressed,
              boxShadow:
                weight === 'bold'
                  ? stroke(palette.actionButton.borderPressed)
                  : null,
              color: palette.actionButton.textPressed,
            },

            // pressed state. prefer aria attributes where possible. only use data
            // attribute when the equivalent aria would be inappropriate.
            '&[data-pressed=true], &[data-expanded=true], &[aria-pressed=true], &[aria-expanded=true]': {
              backgroundColor: palette.actionButton.backgroundSelected,
              boxShadow:
                weight === 'bold'
                  ? stroke(palette.actionButton.borderSelected)
                  : null,
              color: palette.actionButton.textSelected,
            },
          },
        }}
        {...dataAttributes}
        {...restProps}
        // must be after prop spread
        onClick={handleClick}
      >
        {resolvedChildren}
      </Tag>
    );
  }
);
