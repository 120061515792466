import React from 'react';
import { VisuallyHidden } from '@balance-web/a11y';
import { Text } from '@balance-web/text';
import { forwardRefWithAs } from '@balance-web/utils';

type LabelProps = {
  id?: string;
  htmlFor: string;
  visible?: boolean;
};

export const FieldLabel = forwardRefWithAs<'label', LabelProps>(
  ({ as = 'label', visible = true, ...props }, ref) => {
    if (!visible) {
      return <VisuallyHidden as={as} {...props} />;
    }

    return (
      <Text
        as={as}
        ref={ref}
        size="small"
        weight="medium"
        cursor="default"
        {...props}
      />
    );
  }
);
