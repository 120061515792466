import * as React from 'react';

import { createIcon } from '../Icon';
export const MoreHorizontalIcon = createIcon(
  <React.Fragment>
    <circle cx={12} cy={12} r={1} />
    <circle cx={19} cy={12} r={1} />
    <circle cx={5} cy={12} r={1} />
  </React.Fragment>,
  'MoreHorizontalIcon'
);
