/**
 * Grammatical helper for language surrounding dynamic counts.
 *
 * @param {number} n The number of occurences
 * @param {string} s The singular variant
 * @param {string} p The plural variant
 * @param {boolean} i Include the number of occurences in the return string (defaults to true)
 *
 * @example
 * ```
 * plural(3, 'story', 'stories') // '3 stories'
 * plural(0, 'user', 'users') // '0 users'
 * plural(1, 'contact', 'contacts') // '1 contact'
 * plural(2, 'is', 'are', false) // 'are'
 * ```
 */
export function plural(n: number, s: string, p: string, i: boolean = true) {
  const variant = n === 1 ? s : p;
  return i ? `${n} ${variant}` : variant;
}

/** Capitalise the first letter. */
export function sentenceCase(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/** Capitalise the first letter of each word. */
export function titleCase(str: string) {
  return str.replace(/\w\S*/g, sentenceCase);
}
