import {
  CSSObject,
  DataAttributeMap,
  buildDataAttributes,
} from '@balance-web/core';
import { useRawTheme, useTheme } from '@balance-web/theme';
import { hexToRgb } from '@balance-web/utils';

export type UseTextLinkProps = {
  color?: 'base' | 'link';
  data?: DataAttributeMap;
  id?: string;
  /** does not support css */
  css?: never;
  /** does not support style */
  style?: never;
  /** hover state from parent */
  hover?: boolean;
};

export function useTextLink({
  color = 'link',
  data,
  id,
  hover,
}: UseTextLinkProps) {
  const { palette, spacing, typography } = useTheme();
  const rawTheme = useRawTheme();
  const resolvedColor = rawTheme.palette.text[color];

  const testingProps = {
    id,
    ...(data ? buildDataAttributes(data) : undefined),
  };

  const styles: CSSObject = {
    color: resolvedColor,
    cursor: 'pointer',
    fontWeight: typography.fontWeight.medium,
    textDecoration: 'underline',
    // FIXME: avoid colour mutation outside the theme
    textDecorationColor: hover ? resolvedColor : hexToRgb(resolvedColor, 0.32),

    // cover all cases by desaturating and lowering opacity
    '&[aria-disabled=true]': {
      cursor: 'default',
      filter: 'grayscale(100%)',
      mixBlendMode: 'luminosity', // pick up the background hue to avoid harsh neutrals
      opacity: 0.6,
    },

    '&:not([aria-disabled=true])': {
      ':hover, :focus': {
        textDecorationColor: resolvedColor,
      },

      '&[target=_blank]:visited': {
        color: palette.text.linkVisited,
        textDecorationColor: palette.text.linkVisited,
        svg: {
          stroke: palette.text.linkVisited,
        },
      },
    },

    '&:not([aria-disabled=true]).focus-visible': {
      outline: `2px solid ${palette.global.focusRing}`,
      outlineOffset: 2,
    },

    // The svg dims are hard coded because we don't have icon sizing tokens.
    svg: {
      paddingLeft: spacing.xsmall,
      marginBottom: 2,
    },
    '&[data-textsize=small],&[data-textsize=xsmall]': {
      svg: {
        width: 12,
        height: 12,
      },
    },
    '&[data-textsize=medium]': {
      svg: {
        width: 14,
        height: 14,
      },
    },
    '&[data-textsize=large],&[data-textsize=xlarge]': {
      svg: {
        width: 16,
        height: 16,
      },
    },
  };

  return { testingProps, styles };
}
