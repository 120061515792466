import { useCallback, useEffect, useState } from 'react';

// NOTE: keyboard event handler defined here rather than imported from react becase
// the event listener will return a native event, not a synthetic event
type KeyboardHandler = (event: KeyboardEvent) => void;
type UseKeyPressProps = {
  targetKey: string;
  targetElement?: HTMLElement | null;
  downHandler?: KeyboardHandler;
  upHandler?: KeyboardHandler;
  listenWhen: boolean;
};

export const useKeyPress = ({
  targetKey,
  targetElement,
  downHandler,
  upHandler,
  listenWhen,
}: UseKeyPressProps) => {
  // Keep track of whether the target key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // handle key down
  const onDown = useCallback(
    (event) => {
      if (event.key === targetKey) {
        setKeyPressed(true);

        if (typeof downHandler === 'function') {
          downHandler(event);
        }
      }
    },
    [targetKey, downHandler]
  );

  // handle key up
  const onUp = useCallback(
    (event) => {
      if (event.key === targetKey) {
        setKeyPressed(false);

        if (typeof upHandler === 'function') {
          upHandler(event);
        }
      }
    },
    [targetKey, upHandler]
  );

  // add event listeners
  useEffect(() => {
    let target = targetElement || document;

    if (listenWhen) {
      target.addEventListener('keydown', onDown);
      target.addEventListener('keyup', onUp);

      // Remove event listeners on cleanup
      return () => {
        target.removeEventListener('keydown', onDown);
        target.removeEventListener('keyup', onUp);
      };
    }
  }, [listenWhen, onDown, onUp, targetElement]);

  return keyPressed;
};
