import type { AnchorHTMLAttributes } from 'react';
import React, { forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';

// TODO: move out of Balance to some package

type UniversalNextLinkProps = { href: string } & Omit<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  'href'
>;

/**
 * Resolves internal links using the
 * [Next.js Link component](https://nextjs.org/docs/api-reference/next/link), which
 * expects "href" to begin with a slash e.g. `href="/page"`. Uses a traditional
 * anchor element for everything else e.g. external, hash, tel, mailto.
 *
 * For compatibility with TS + Balance the "href" property may only accept a
 * string, so URL Objects must be resolved ahead of time. We recommend the [url
 * package](https://www.npmjs.com/package/url) for complex cases, though most of
 * the time it's simple to do this manually.
 */
export const UniversalNextLink = forwardRef<
  HTMLAnchorElement,
  UniversalNextLinkProps
>(({ href, ...props }, ref) => {
  const shouldUseNext = href?.startsWith('/');

  return shouldUseNext ? (
    <NextLink href={href} passHref>
      <a ref={ref} {...props} />
    </NextLink>
  ) : (
    <a ref={ref} href={href} {...props} />
  );
});

UniversalNextLink.displayName = 'UniversalNextLink';
