import React, { ReactNode } from 'react';
import { Stack } from '@balance-web/stack';

import { Header } from './Header';
import { Body } from './Body';
import { Footer } from './Footer';
import { Form } from './Form';

export const PopoverContent = ({ children }: { children: ReactNode }) => {
  return <Stack>{children}</Stack>;
};

PopoverContent.Header = Header;
PopoverContent.Body = Body;
PopoverContent.Form = Form;
PopoverContent.Footer = Footer;
