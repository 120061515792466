/** @jsx jsx */

import type { KeyboardEvent, MutableRefObject, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Fragment, useCallback, useRef } from 'react';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { jsx } from '@balance-web/core';
import { Portal } from '@balance-web/portal';
import { useTheme } from '@balance-web/theme';
import { makeId } from '@balance-web/utils';
import { Blanket } from '@balance-web/blanket';
import { Elevate } from '@balance-web/elevate';
import useTimeout from '@rooks/use-timeout';

import { useDrawerManager } from './context';
import type { TransitionState } from './types';
import { DrawerControllerContextProvider } from './DrawerController';

// NOTE: we may need expose something from the popover package to
// influence Popper.js modifiers via React context. Notable problems may come
// from the "boundary" element e.g. the scroll parent of the popover and if we
// need to change to the position strategy

const widths = {
  narrow: 448,
  wide: 720,
};
const easing = 'cubic-bezier(0.2, 0, 0, 1)';

export type DrawerBaseProps = {
  children: ReactNode;
  id?: string;
  initialFocusRef?: MutableRefObject<any>;
  onClose: () => void;
  transitionState: TransitionState;
  onSubmit?: () => void;
  width?: keyof typeof widths;
};

const blanketTransition = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const DrawerBase = ({
  children,
  id,
  initialFocusRef,
  onClose,
  onSubmit,
  width = 'narrow',
  transitionState,
  ...props
}: DrawerBaseProps) => {
  const theme = useTheme();
  const containerRef = useRef(null);
  const uniqueKey = makeId('drawer', id);

  const [animating, setAnimating] = useState(true);

  const animationTimeout = useTimeout(() => {
    setAnimating(false);
  }, 320);

  // sync drawer state
  let drawerDepth = useDrawerManager(uniqueKey);

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && !event.defaultPrevented) {
      event.preventDefault();
      onClose();
    }
  };

  const activateFocusLock = useCallback(() => {
    setTimeout(() => {
      if (initialFocusRef && initialFocusRef.current) {
        initialFocusRef.current.focus();
      }
    }, 320);
  }, [initialFocusRef]);

  const dialogTransition = getDialogTransition(drawerDepth);

  let Tag: 'div' | 'form' = 'div';
  if (onSubmit) {
    Tag = 'form';
    let oldOnSubmit = onSubmit;
    // @ts-ignore
    onSubmit = (event: any) => {
      if (!event.defaultPrevented) {
        event.preventDefault();
        oldOnSubmit();
      }
    };
  }

  useEffect(
    function initialiseAnimationTimeout() {
      animationTimeout.start();

      return animationTimeout.clear;
    },
    [animationTimeout]
  );

  return (
    <Portal>
      <Fragment>
        <Blanket
          onClick={onClose}
          css={{ transition: `opacity 150ms linear` }}
          style={blanketTransition[transitionState]}
        />
        <FocusLock
          autoFocus={!animating}
          returnFocus
          onActivation={activateFocusLock}
          lockProps={{ style: { display: 'contents' } }}
        >
          <RemoveScroll enabled forwardProps>
            <Tag
              id={id}
              onSubmit={onSubmit}
              aria-modal="true"
              role="dialog"
              ref={containerRef}
              tabIndex={-1}
              onKeyDown={onKeyDown}
              style={dialogTransition[transitionState]}
              css={{
                backgroundColor: theme.palette.background.base,
                borderRadius: theme.radii.large,
                bottom: theme.spacing.small,
                boxShadow: theme.shadow.large,
                position: 'fixed',
                right: theme.spacing.small,
                top: theme.spacing.small,
                transition: `transform 150ms ${easing}`,
                width: widths[width],
                zIndex: theme.elevation.modal,

                // flex layout must be applied here so content will grow/shrink properly
                display: 'flex',
                flexDirection: 'column',
              }}
              {...props}
            >
              <DrawerControllerContextProvider value={null}>
                <Elevate>{children}</Elevate>
              </DrawerControllerContextProvider>
            </Tag>
          </RemoveScroll>
        </FocusLock>
      </Fragment>
    </Portal>
  );
};

// Utils
// ------------------------------

function getDialogTransition(depth: number) {
  let scaleInc = 0.05;
  let transformValue = `scale(${1 - scaleInc * depth}) translateX(-${
    depth * 40
  })`;

  return {
    entering: { transform: 'translateX(100%)' },
    entered: { transform: transformValue },
    exiting: { transform: 'translateX(100%)' },
    exited: { transform: 'translateX(100%)' },
  };
}
