/** @jsx jsx */

import { jsx } from '@balance-web/core';
import { HelpCircleIcon } from '@balance-web/icon/icons/HelpCircleIcon';
import { useTheme } from '@balance-web/theme';
import { Tooltip } from '@balance-web/tooltip';
import type { TooltipProps } from '@balance-web/tooltip';

type HelpIndicatorProps = {
  /** The content of the tooltip. */
  content: TooltipProps['content'];
  placement?: 'top' | 'right' | 'bottom' | 'left';
};

export const HelpIndicator = ({ content, placement }: HelpIndicatorProps) => {
  const { spacing } = useTheme();

  return (
    <Tooltip content={content} placement={placement}>
      <span
        aria-label="Help"
        css={{
          alignItems: 'center',
          background: 0,
          border: 0,
          cursor: 'help',
          display: 'flex',
          justifyContent: 'center',
          marginLeft: spacing.xsmall,
          padding: 0,
          position: 'relative',

          // increase hit target
          '::before': {
            bottom: -spacing.xsmall,
            content: '" "',
            left: -spacing.xsmall,
            position: 'absolute',
            right: -spacing.xsmall,
            top: -spacing.xsmall,
          },
        }}
      >
        <HelpCircleIcon color="dim" size="small" />
      </span>
    </Tooltip>
  );
};
