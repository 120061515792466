/** @jsx jsx */

import { Box } from '@balance-web/box';
import { Button } from '@balance-web/button';
import { jsx } from '@balance-web/core';
import { Heading } from '@balance-web/heading';
import { useTheme } from '@balance-web/theme';
import { makeId, useId } from '@balance-web/utils';
import { Text } from '@balance-web/text';
import type { FormEvent } from 'react';
import { Flex } from '@balance-web/flex';

import type { ModalSize } from './size';
import { useModalWidth } from './size';
import { DialogBase } from './DialogBase';

type Action = {
  action: () => void;
  label: string;
};

type SubmitAction = {
  action: (event: FormEvent<HTMLFormElement>) => void;
  label: string;
  loading?: boolean;
};

type FormDialogProps = {
  actions: {
    cancel: Action;
    confirm: SubmitAction;
  };
  id?: string;
  isOpen: boolean;
  children: React.ReactNode;
  title: string;
  size?: ModalSize;
  autoFocus?: boolean;
};

export const FormDialog = ({
  actions,
  isOpen,
  children,
  title,
  id,
  size = 'small',
  autoFocus = true,
}: FormDialogProps) => {
  const { cancel, confirm } = actions;
  const theme = useTheme();
  const instanceId = useId(id);
  const headingId = makeId(instanceId, 'heading');

  const modalWidth = useModalWidth(size);

  const onClose = () => {
    cancel.action();
  };

  const formId = makeId(instanceId, 'form');

  return (
    <DialogBase
      id={instanceId}
      isOpen={isOpen}
      onClose={onClose}
      width={modalWidth}
      aria-labelledby={headingId}
      elevation="modal"
      autoFocus={autoFocus}
    >
      <Box as="form" id={formId} onSubmit={confirm.action} paddingX="xxlarge">
        <Box paddingTop="xlarge" paddingBottom="large">
          <Heading id={headingId} level="3">
            {title}
          </Heading>
        </Box>

        <Box paddingY="xlarge">
          <Text color="muted">{children}</Text>
        </Box>

        <div
          css={{
            display: 'flex',
            justifyContent: 'start',
            flexWrap: 'wrap',
            flexDirection: 'row',
            paddingTop: theme.spacing.large,
            paddingBottom: theme.spacing.xxlarge,
            gap: theme.spacing.large,
          }}
        >
          <Button
            id={makeId(instanceId, 'cancel')}
            disabled={confirm.loading}
            key={cancel.label}
            label={cancel.label}
            onClick={cancel.action}
            variant="text"
            colorScheme="tertiary"
            size="small"
          />
          <Flex flex="1" justifyContent="end">
            <Button
              type="submit"
              key={confirm.label}
              label={confirm.label}
              loading={confirm.loading}
              size="small"
            />
          </Flex>
        </div>
      </Box>
    </DialogBase>
  );
};
