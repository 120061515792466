/** @jsx jsx */

import { forwardRef } from 'react';
import { buildDataAttributes, jsx, useLinkComponent } from '@balance-web/core';
import type { LinkComponentProps } from '@balance-web/core';
import { ExternalLinkIcon } from '@balance-web/icon';
import { useTextContext } from '@balance-web/text';

import { useTextLink } from './useTextLink';
import type { UseTextLinkProps } from './useTextLink';

export type TextLinkProps = LinkComponentProps & UseTextLinkProps;

/**
 * Text links take users to another place in the application, using the "href"
 * prop. If you are *only* providing "onClick" use the `TextLinkButton` instead.
 *
 * @see https://balance.reckon.com/package/text-link
 */
export const TextLink = forwardRef<HTMLAnchorElement, TextLinkProps>(
  ({ color = 'link', data, id, children, ...consumerProps }, ref) => {
    const LinkComponent = useLinkComponent();
    const { testingProps, styles } = useTextLink({ color, data, id });

    const textContext = useTextContext();

    const dataAttributes = buildDataAttributes({
      textsize: textContext?.size || 'small',
    });

    return (
      <LinkComponent
        ref={ref}
        css={styles}
        {...testingProps}
        {...consumerProps}
        {...dataAttributes}
      >
        {children}
        {consumerProps.target === '_blank' && (
          <ExternalLinkIcon color={color === 'base' ? 'base' : 'active'} />
        )}
      </LinkComponent>
    );
  }
);
