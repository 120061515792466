import React, { useMemo } from 'react';
import type { ReactNode } from 'react';
import { merge } from 'lodash';
import { UniversalNextLink } from '@balance-web/next-link';
import { Core } from '@balance-web/core';
import type { LinkComponent } from '@balance-web/core';
import type { BalanceTheme, BalanceThemeRaw } from '@balance-web/theme';
import type { DeepPartial } from '@balance-web/utils';
import { cloneDeep } from 'lodash';

import {
  lightTheme,
  lightThemeCSSVars,
  lightThemeRaw,
} from '@reckon-web/reckon-balance-theme';

// UNDO THIS when csstype patch solution is released.
// @ts-ignore
const customTheme: BalanceTheme = { ...lightTheme, name: 'Reckon light' };

export type ThemeProviderProps = {
  themeOverrides?: DeepPartial<BalanceTheme>;
  linkComponent?: LinkComponent;
  children?: ReactNode;
};

function toCSSVars(obj: Record<string, any>, parentKey = '') {
  let vars: Record<string, string | number> = {};

  Object.keys(obj).forEach((key) => {
    let fullKey = parentKey ? `${parentKey}-${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(vars, toCSSVars(obj[key], fullKey));
    } else {
      vars[`--${fullKey}`] = obj[key];
    }
  });

  return vars;
}

export const ThemeProvider = ({
  themeOverrides,
  linkComponent,
  children,
}: ThemeProviderProps) => {
  const originThemeCopy = useMemo(() => {
    return cloneDeep(customTheme);
  }, []);
  const mergedTheme = useMemo(() => {
    return merge(originThemeCopy, themeOverrides);
  }, [originThemeCopy, themeOverrides]);

  const themeCSSVars = useMemo(() => {
    const cssVarsOverrides = toCSSVars(themeOverrides || {});

    return merge(lightThemeCSSVars, cssVarsOverrides);
  }, [themeOverrides]);

  const mergedThemeRaw = useMemo(() => {
    return merge(lightThemeRaw, (themeOverrides as unknown) as BalanceThemeRaw);
  }, [themeOverrides]);

  return (
    <Core
      linkComponent={linkComponent || UniversalNextLink}
      theme={mergedTheme}
      themeCSSVars={themeCSSVars}
      themeRaw={mergedThemeRaw}
    >
      {children}
    </Core>
  );
};
