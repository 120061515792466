/** @jsx jsx */

import type {
  ChangeEvent,
  ComponentType,
  HTMLAttributes,
  ReactNode,
  SVGAttributes,
} from 'react';
import { Fragment, forwardRef, useEffect, useRef, useState } from 'react';
import { useLocation } from '@reach/router';
import { Link, graphql, useStaticQuery } from 'gatsby';
import type { LinkComponentProps } from '@balance-web/core';
import { jsx } from '@balance-web/core';
import { Field } from '@balance-web/field';
import { Box } from '@balance-web/box';
import { Columns } from '@balance-web/columns';
import { Badge } from '@balance-web/badge';
import { useMediaQuery } from '@balance-web/media-query';
import type { IconProps } from '@balance-web/icon';
import { BookIcon } from '@balance-web/icon/icons/BookIcon';
import { Edit3Icon } from '@balance-web/icon/icons/Edit3Icon';
import { MapIcon } from '@balance-web/icon/icons/MapIcon';
import { PackageIcon } from '@balance-web/icon/icons/PackageIcon';
import { SearchInput } from '@balance-web/search-input';
import { Text } from '@balance-web/text';
import { useRawTheme, useTheme } from '@balance-web/theme';
import { Tooltip } from '@balance-web/tooltip';
import { forwardRefWithAs, hexToRgb } from '@balance-web/utils';
import { Button } from '@balance-web/button';
import { ExternalLinkIcon } from '@balance-web/icon';
import { createUrl } from 'playroom/utils';

import { ReckonBalanceCore } from '@reckon-web/reckon-balance-core';

import type { PagesForNavigationQuery } from '../../graphql-types';
import { useIsPathnameActive } from '../components/useIsPathnameActive';

export const HEADER_HEIGHT = 80;
export const FOOTER_HEIGHT = 80;

const searchShortcut = '  ⌘ K';

function AppWrapper({ children }: { children: ReactNode }) {
  return (
    <ReckonBalanceCore themeMode="light" linkComponent={LinkComponent}>
      <AppLayout>{children}</AppLayout>
    </ReckonBalanceCore>
  );
}

function AppLayout({ children }: { children: ReactNode }) {
  let location = useLocation();
  let theme = useTheme();
  let [searchInput, setSearchInput] = useState('');
  let [navIsOpen, setNavOpen] = useState(false);
  let toggleNav = () => {
    return setNavOpen((s) => {
      return !s;
    });
  };
  let currentSection = location.pathname.split('/')[1];

  const sampleCode = `return <Box>Your code goes here.</Box>`;

  const playroomURL = createUrl({
    baseUrl:
      process.env.NODE_ENV !== 'production'
        ? 'http://localhost:9000'
        : '/playroom',
    code: `<Render>\n  {() => {\n    ${sampleCode
      .split('\n')
      .join('\n    ')}\n  }}\n</Render>`,
    widths: [1024],
  });

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'k' && event.metaKey) {
        searchInputRef.current?.focus();
      }
    };
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  // navigation pages
  let pagesForNavigation = useStaticQuery<PagesForNavigationQuery>(graphql`
    query PagesForNavigation {
      allSitePage(
        filter: {
          context: { section: { regex: "/.*/" }, title: { regex: "/.*/" } }
        }
      ) {
        nodes {
          context {
            section
            title
            deprecated
            discouraged
          }
          path
        }
      }
      introduction: allSitePage(
        filter: { path: { glob: "/introduction/**" } }
      ) {
        nodes {
          context {
            title
          }
          path
        }
      }
      content: allSitePage(filter: { path: { glob: "/content/**" } }) {
        nodes {
          context {
            title
          }
          path
        }
      }
      patterns: allSitePage(filter: { path: { glob: "/patterns/**" } }) {
        nodes {
          context {
            title
            section
          }
          path
        }
      }
      theming: allSitePage(filter: { path: { glob: "/theming/**" } }) {
        nodes {
          context {
            title
            section
          }
          path
        }
      }
    }
  `);

  let packageNav: Record<string, { name: string; href: string }[]> = {};
  pagesForNavigation.allSitePage.nodes.forEach((value) => {
    let section = value.context!.section!;
    if (!packageNav[section]) {
      packageNav[section] = [];
    }
    packageNav[section].push({
      name: value.context!.title!,
      href: value.path,
    });
  });

  const mapNodesToPackages = (nodes: any[], page: string) => {
    return nodes.map((n) => {
      return {
        name: n.context.title,
        path: n.path,
        href: n.path,
        deprecated: n.context.deprecated,
        discouraged: n.context.discouraged,
        page,
      };
    });
  };

  // search pages
  const unfilteredPackages = pagesForNavigation.allSitePage.nodes.map((n) => {
    return {
      name: n.context!.title!,
      href: n.path,
      deprecated: n.context!.deprecated,
      discouraged: n.context!.discouraged,
    };
  });

  const combinedPackages = [
    ...unfilteredPackages.map((p) => {
      return { ...p, page: 'Packages' };
    }),
    ...mapNodesToPackages(pagesForNavigation.content.nodes, 'Content'),
    ...mapNodesToPackages(
      pagesForNavigation.introduction.nodes,
      'Introduction'
    ),
    ...mapNodesToPackages(pagesForNavigation.patterns.nodes, 'Patterns'),
  ];

  const filteredPackages = combinedPackages.filter((n) => {
    return n.name.toLowerCase().includes(searchInput.toLowerCase());
  });
  const groupedFilteredPackages = filteredPackages.reduce((acc, _package) => {
    return {
      ...acc,
      [_package.page]: [...(acc[_package.page] || []), _package],
    };
  }, {} as any);

  return (
    <div
      css={{
        display: 'grid',
        gridTemplateColumns: `320px 1fr`,
        gridTemplateRows: `${HEADER_HEIGHT}px 1fr auto`,
        minHeight: '100vh',
      }}
    >
      <div
        css={{
          alignItems: 'center',
          backgroundColor: theme.palette.background.muted,
          borderRight: `1px solid ${theme.palette.global.border}`,
          borderBottom: `1px solid ${theme.palette.global.border}`,
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: theme.spacing.xlarge,
          paddingRight: theme.spacing.xlarge,
          position: 'sticky',
          top: 0,
          zIndex: 3,
        }}
      >
        <Link
          css={{
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            textDecoration: 'none',
          }}
          to="/introduction/welcome"
        >
          <img
            alt="Balance Logo"
            src="/balance-logo-mark.svg"
            css={{
              height: theme.sizing.medium,
              width: 'auto',
              marginRight: theme.spacing.medium,
            }}
          />
          <div>
            <Text size="xlarge" weight="bold">
              Balance
            </Text>
            <Text size="small" color="muted">
              Reckon Design System
            </Text>
          </div>
        </Link>
      </div>
      <header
        css={{
          alignItems: 'center',
          backgroundColor: theme.palette.background.base,
          borderBottom: `1px solid ${theme.palette.global.border}`,
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: theme.spacing.xlarge,
          paddingRight: theme.spacing.xlarge,
          position: 'sticky',
          top: 0,
          zIndex: 3,
        }}
      >
        <HeaderNav css={{ display: 'flex' }}>
          <HeaderNavItem
            icon={MapIcon}
            isActive={currentSection === 'introduction'}
            to="/introduction/welcome"
            label="Introduction"
          />
          <HeaderNavItem
            icon={PackageIcon}
            isActive={currentSection === 'package'}
            to="/package/getting-started"
            label="Packages"
          />
          <HeaderNavItem
            icon={Edit3Icon}
            isActive={currentSection === 'content'}
            to="/content/introduction"
            label="Content"
          />
          <HeaderNavItem
            icon={BookIcon}
            isActive={currentSection === 'patterns'}
            to="/patterns/introduction"
            label="Patterns"
          />
          <HeaderNavItem
            icon={BookIcon}
            isActive={currentSection === 'theming'}
            to="/theming/introduction"
            label="Theming"
          />
        </HeaderNav>
        <Button
          as="a"
          href={playroomURL}
          target="_blank"
          label="Open playroom"
          variant="text"
          iconAfter={ExternalLinkIcon}
        />
      </header>

      <SidebarNav isOpen={navIsOpen} toggleNav={toggleNav}>
        <Box paddingX="medium" paddingBottom="small">
          <Field label={`Search ${searchShortcut}`} labelVisible={false}>
            <SearchInput
              ref={searchInputRef}
              placeholder={`Search ${searchShortcut}`}
              value={searchInput}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                return setSearchInput(e.target.value);
              }}
              size="small"
              onClear={() => {
                return setSearchInput('');
              }}
              style={{ minWidth: 140 }}
            />
          </Field>
        </Box>
        {searchInput ? (
          <Fragment>
            <Box marginX="xlarge" marginY="large">
              <Text size="small" color="dim">
                {filteredPackages?.length
                  ? `Found ${filteredPackages.length} results...`
                  : 'No matching results...'}
              </Text>
            </Box>

            {groupedFilteredPackages['Packages']?.length ? (
              <SidebarNavGroup title="Packages">
                {groupedFilteredPackages['Packages'].map((page: any) => {
                  return (
                    <SidebarNavItem
                      key={page.name}
                      to={page.href}
                      onClick={() => {
                        return setSearchInput('');
                      }}
                    >
                      <Columns alignY="center">
                        <Fragment>{page.name}</Fragment>
                        {page.deprecated && (
                          <Badge label="Deprecated" tone="cautious" />
                        )}
                        {page.discouraged && (
                          <Badge label="Discouraged" tone="neutral" />
                        )}
                      </Columns>
                    </SidebarNavItem>
                  );
                })}
              </SidebarNavGroup>
            ) : null}
            {groupedFilteredPackages['Patterns']?.length ? (
              <SidebarNavGroup title="Patterns">
                {groupedFilteredPackages['Patterns'].map((page: any) => {
                  return (
                    <SidebarNavItem
                      key={page.name}
                      to={page.href}
                      onClick={() => {
                        return setSearchInput('');
                      }}
                    >
                      <Columns alignY="center">
                        <Fragment>{page.name}</Fragment>
                        {page.deprecated && (
                          <Badge label="Deprecated" tone="cautious" />
                        )}
                        {page.discouraged && (
                          <Badge label="Discouraged" tone="neutral" />
                        )}
                      </Columns>
                    </SidebarNavItem>
                  );
                })}
              </SidebarNavGroup>
            ) : null}
            {groupedFilteredPackages['Introduction']?.length ? (
              <SidebarNavGroup title="Introduction">
                {groupedFilteredPackages['Introduction'].map((page: any) => {
                  return (
                    <SidebarNavItem
                      key={page.name}
                      to={page.href}
                      onClick={() => {
                        return setSearchInput('');
                      }}
                    >
                      <Columns alignY="center">
                        <Fragment>{page.name}</Fragment>
                        {page.deprecated && (
                          <Badge label="Deprecated" tone="cautious" />
                        )}
                        {page.discouraged && (
                          <Badge label="Discouraged" tone="neutral" />
                        )}
                      </Columns>
                    </SidebarNavItem>
                  );
                })}
              </SidebarNavGroup>
            ) : null}
            {groupedFilteredPackages['Content']?.length ? (
              <SidebarNavGroup title="Content">
                {groupedFilteredPackages['Content'].map((page: any) => {
                  return (
                    <SidebarNavItem
                      key={page.name}
                      to={page.href}
                      onClick={() => {
                        return setSearchInput('');
                      }}
                    >
                      <Columns alignY="center">
                        <Fragment>{page.name}</Fragment>
                        {page.deprecated && (
                          <Badge label="Deprecated" tone="cautious" />
                        )}
                        {page.discouraged && (
                          <Badge label="Discouraged" tone="neutral" />
                        )}
                      </Columns>
                    </SidebarNavItem>
                  );
                })}
              </SidebarNavGroup>
            ) : null}
            {groupedFilteredPackages['Theming']?.length ? (
              <SidebarNavGroup title="Theming">
                {groupedFilteredPackages['Theming'].map((page: any) => {
                  return (
                    <SidebarNavItem
                      key={page.name}
                      to={page.href}
                      onClick={() => {
                        return setSearchInput('');
                      }}
                    >
                      <Columns alignY="center">
                        <Fragment>{page.name}</Fragment>
                        {page.deprecated && (
                          <Badge label="Deprecated" tone="cautious" />
                        )}
                        {page.discouraged && (
                          <Badge label="Discouraged" tone="neutral" />
                        )}
                      </Columns>
                    </SidebarNavItem>
                  );
                })}
              </SidebarNavGroup>
            ) : null}
          </Fragment>
        ) : (
          <Fragment>
            {currentSection === 'introduction' &&
              pagesForNavigation.introduction.nodes
                .sort(pageSort)
                .map((page) => {
                  return (
                    <SidebarNavItem to={page.path} key={page.path}>
                      {page.context!.title}
                    </SidebarNavItem>
                  );
                })}

            {currentSection === 'content' &&
              pagesForNavigation.content.nodes.sort(pageSort).map((page) => {
                return (
                  <SidebarNavItem to={page.path} key={page.path}>
                    {page.context!.title}
                  </SidebarNavItem>
                );
              })}

            {currentSection === 'patterns' &&
              pagesForNavigation.patterns.nodes.sort(pageSort).map((page) => {
                return (
                  <SidebarNavItem to={page.path} key={page.path}>
                    {page.context!.title}
                  </SidebarNavItem>
                );
              })}

            {currentSection === 'theming' &&
              pagesForNavigation.theming.nodes.sort(pageSort).map((page) => {
                return (
                  <SidebarNavItem to={page.path} key={page.path}>
                    {page.context!.title}
                  </SidebarNavItem>
                );
              })}

            {currentSection === 'package' && (
              <Fragment>
                {/* TODO: fetch from gatsby */}
                <SidebarNavGroup key="package" title="Intro">
                  <SidebarNavItem to="/package/getting-started">
                    Getting started
                  </SidebarNavItem>
                  <SidebarNavItem to="/package/development-workflow">
                    Development workflow
                  </SidebarNavItem>
                </SidebarNavGroup>
                <SidebarNavGroup key="packages" title="Packages">
                  {unfilteredPackages.sort().map((page) => {
                    return (
                      <SidebarNavItem key={page.name} to={page.href}>
                        <Columns alignY="center">
                          <Fragment>{page.name}</Fragment>
                          {page.deprecated && (
                            <Badge label="Deprecated" tone="cautious" />
                          )}
                          {page.discouraged && (
                            <Badge label="Discouraged" tone="neutral" />
                          )}
                        </Columns>
                      </SidebarNavItem>
                    );
                  })}
                </SidebarNavGroup>
              </Fragment>
            )}
          </Fragment>
        )}
      </SidebarNav>

      <div css={{ minWidth: 0 }}>{children}</div>

      <div
        css={{
          backgroundColor: theme.palette.background.muted,
          borderRight: `1px solid ${theme.palette.global.border}`,
        }}
      />
      <footer
        css={{
          alignItems: 'center',
          boxShadow: `0 -1px 0 ${theme.palette.global.border}`,
          color: theme.palette.text.dim,
          display: 'flex',
          fontSize: theme.typography.fontSize.small,
          height: FOOTER_HEIGHT,
          justifyContent: 'space-between',
          paddingLeft: theme.spacing.xxlarge,
          paddingRight: theme.spacing.xxlarge,

          a: {
            color: theme.palette.text.muted,
          },
        }}
      >
        <div
          css={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <ReckonLogo
            css={{
              flexShrink: 0,
              height: 24,
              marginRight: theme.spacing.small,
              width: 'auto',
            }}
          />
          <span>
            Copyright &copy; {new Date().getFullYear()}{' '}
            <a
              href="https://www.reckon.com/au/"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Reckon
            </a>
            . Designed and developed in partnership with{' '}
            <a
              href="https://www.thinkmill.com.au/"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Thinkmill
            </a>
            .
          </span>
        </div>
        <div css={{ alignItems: 'center', display: 'flex' }}>
          <HeaderService
            alt="Bitbucket logo"
            tooltip="BitBucket"
            src="/bitbucket-logo-mark.svg"
            href="https://bitbucket.org/reckonhq/reckon-frontend/"
          />
          <HeaderService
            alt="Jira software logo"
            tooltip="Jira"
            src="/jira-logo-mark.svg"
            href="https://reckon.atlassian.net/jira/software/projects/RF/boards/145"
          />
          <HeaderService
            alt="Confluence logo"
            tooltip="Confluence"
            src="/confluence-logo-mark.svg"
            href="https://reckon.atlassian.net/wiki/spaces/RKNDS/overview?homepageId=792462689"
          />
        </div>
      </footer>
    </div>
  );
}

// ==============================
// Styled Components
// ==============================

// Link component
// ------------------------------

const LinkComponent = forwardRef<HTMLAnchorElement, LinkComponentProps>(
  ({ href, ...props }, ref) => {
    // internal: use Gatsby Link with `to` prop
    if (href && href.startsWith('/')) {
      // @ts-ignore
      return <Link ref={ref} to={href} {...props} />;
    }

    // hash: do nothing
    // external: open in new tab, safely
    const extra =
      href && href.startsWith('#')
        ? {}
        : {
            target: '_blank',
            rel: 'noopener noreferrer',
          };

    return <a href={href} {...props} {...extra} />;
  }
);

// Header
// ------------------------------

const HeaderNav = ({
  children,
  ...props
}: {} & HTMLAttributes<HTMLElement>) => {
  return (
    <nav css={{ display: 'flex' }} {...props}>
      {children}
    </nav>
  );
};
const HeaderNavItem = ({
  label,
  icon: Icon,
  isActive,
  to,
  ...props
}: {
  label: string;
  icon: ComponentType<IconProps>;
  isActive: boolean;
  to: string;
}) => {
  const { palette, spacing, typography } = useTheme();

  return (
    <Link
      aria-label={label}
      title={label}
      aria-current={isActive ? 'location' : undefined}
      to={to}
      css={{
        alignItems: 'center',
        boxShadow: `0 0 0 ${palette.text.base}`,
        color: palette.text.muted,
        display: 'flex',
        flexDirection: 'row',
        fontSize: typography.fontSize.medium,
        fontWeight: typography.fontWeight.medium,
        height: HEADER_HEIGHT,
        justifyContent: 'center',
        marginRight: '2vw',
        textDecoration: 'none',
        transition: 'box-shadow 250ms',

        ':hover, &.focus-visible': {
          boxShadow: `inset 0 -1px 0 ${palette.text.base}`,
        },

        '&[aria-current]': {
          boxShadow: `inset 0 -3px 0 ${palette.text.base}`,
          color: palette.text.base,
        },
      }}
      {...props}
    >
      <Icon size="small" />
      <span css={{ marginLeft: spacing.small }}>{label}</span>
    </Link>
  );
};

type ServiceProps = {
  alt: string;
  href: string;
  src: string;
  tooltip: string;
};
const HeaderService = ({ alt, href, src, tooltip, ...props }: ServiceProps) => {
  const theme = useTheme();
  return (
    <Tooltip content={tooltip}>
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        css={{
          height: theme.sizing.xsmall,
          marginLeft: theme.spacing.medium,
          opacity: 0.6,
          transition: 'opacity 150ms',

          ':hover, &.focus-visible': { opacity: 1 },
        }}
      >
        <img
          alt={alt}
          src={src}
          css={{
            height: theme.sizing.xsmall,
            width: 'auto',
          }}
        />
      </a>
    </Tooltip>
  );
};

// Sidebar
// ------------------------------

const SidebarNav = ({
  children,
  isOpen,
  toggleNav,
  ...props
}: {
  isOpen: boolean;
  toggleNav: () => void;
} & HTMLAttributes<HTMLElement>) => {
  const { palette, spacing } = useTheme();
  const { minBreak } = useMediaQuery();

  return (
    <nav
      css={{
        backgroundColor: palette.background.muted,
        borderRight: `1px solid ${palette.global.border}`,
        boxSizing: 'border-box',
        minWidth: 0,
        paddingBottom: spacing.xlarge,
        paddingTop: spacing.large,
        WebkitOverflowScrolling: 'touch',

        [minBreak('large')]: {
          height: `calc(100vh - ${HEADER_HEIGHT})`,
          marginBottom: -FOOTER_HEIGHT,
          overflowX: 'hidden',
          overflowY: 'auto',
          position: 'sticky',
          top: HEADER_HEIGHT,
        },
      }}
      {...props}
    >
      {children}
    </nav>
  );
};
const SidebarNavGroup = ({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) => {
  const { palette, spacing, typography } = useTheme();

  return (
    <div
      css={{
        marginBottom: spacing.medium,
        marginTop: spacing.large,

        ':first-of-type': { marginTop: 0 },
      }}
    >
      <h4
        css={{
          alignItems: 'center',
          background: 0,
          border: 0,
          color: palette.text.dim,
          display: 'flex',
          fontSize: typography.fontSize.xsmall,
          fontWeight: typography.fontWeight.bold,
          marginBottom: spacing.small,
          marginTop: 0,
          padding: `0 ${spacing.xlarge}`,
          outline: 0,
          position: 'relative',
          width: '100%',
          textTransform: 'uppercase',
        }}
      >
        {titleCase(title)}
      </h4>
      {children}
    </div>
  );
};

const SidebarNavItem = forwardRefWithAs<typeof Link, {}>(
  ({ as: Tag = Link, ...props }, ref) => {
    const { palette, radii, spacing, typography } = useTheme();
    const rawTheme = useRawTheme();
    const isActive = useIsPathnameActive(
      (props as any).to || (props as any).href
    );

    return (
      <Tag
        ref={ref}
        aria-current={isActive ? 'page' : undefined}
        css={{
          alignItems: 'center',
          borderRadius: radii.xsmall,
          color: palette.listItem.text,
          cursor: 'pointer',
          display: 'flex',
          fontSize: typography.fontSize.small,
          padding: `${spacing.small} ${spacing.large}`,
          marginTop: spacing.xxsmall,
          marginLeft: spacing.xlarge,
          marginRight: spacing.xlarge,
          position: 'relative',
          textDecoration: 'none',

          ':hover, &.focus-visible': {
            backgroundColor: hexToRgb(rawTheme.palette.text.link, 0.08),
            color: palette.text.link,
          },
          ':active': {
            backgroundColor: hexToRgb(rawTheme.palette.text.link, 0.16),
          },

          '&[aria-current=page]': {
            backgroundColor: palette.listItem.backgroundSelected,
            color: palette.listItem.text,
            fontWeight: typography.fontWeight.medium,
          },
        }}
        {...props}
      />
    );
  }
);

const ReckonLogo = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 180 48"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
      role="img"
      {...props}
    >
      <title>Reckon Logo</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.786 0H12.214C5.486 0 0 5.486 0 12.214V48h35.786C42.514 48 48 42.514 48 35.786V12.214C47.957 5.486 42.514 0 35.786 0zM25.365 11.908H14.61v24.071h4.242v-8.395h5.313l5.913 8.395h5.056l-6.513-9.123c3.342-.942 5.699-3.34 5.699-7.282v-.085c0-2.099-.728-3.855-2.014-5.183-1.542-1.499-3.899-2.398-6.941-2.398zm4.67 7.923c0 2.442-1.928 4.027-4.97 4.027h-6.213v-8.096h6.17c3.17 0 5.013 1.414 5.013 3.984v.085z"
      />
      <path d="M75.425 36.057l-5.913-8.352h-5.313V36.1H60V12.028h10.712c1.37 0 2.613.171 3.727.514 1.114.343 2.057.857 2.828 1.5a6.126 6.126 0 011.757 2.398c.428.942.6 2.013.6 3.17 0 2.012-.514 3.597-1.585 4.797-1.072 1.199-2.443 2.056-4.157 2.527l6.513 9.123h-4.97zm-.086-16.19c0-1.329-.428-2.356-1.328-2.999-.857-.685-2.1-1.028-3.685-1.028H64.2v8.096h6.213c1.5 0 2.742-.386 3.599-1.114.9-.728 1.328-1.713 1.328-2.956zM86.522 28.476c.257 1.499.857 2.613 1.8 3.426.943.771 2.1 1.2 3.428 1.2 1.028 0 1.928-.172 2.699-.557.771-.386 1.5-.9 2.228-1.628l2.442 2.185c-.857 1.028-1.928 1.842-3.127 2.484-1.2.6-2.657.942-4.328.942-1.285 0-2.528-.214-3.642-.685a9.792 9.792 0 01-2.956-1.928c-.857-.813-1.5-1.841-1.971-2.998-.472-1.156-.729-2.441-.729-3.855 0-1.285.214-2.527.643-3.683.428-1.157 1.028-2.185 1.842-3.042a8.156 8.156 0 012.785-2.013c1.072-.514 2.271-.728 3.557-.728 1.457 0 2.699.257 3.813.771 1.071.514 2.014 1.242 2.7 2.142a8.71 8.71 0 011.628 3.127c.385 1.199.557 2.441.557 3.726v.557c0 .171-.043.385-.043.6H86.522v-.043zm9.298-2.742c-.086-.642-.214-1.285-.428-1.884a4.662 4.662 0 00-2.314-2.57c-.557-.257-1.157-.343-1.843-.343-1.285 0-2.356.428-3.17 1.328-.857.9-1.328 2.013-1.543 3.47h9.298z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.343 33.102c-.429.514-.9.942-1.414 1.37a8.552 8.552 0 01-1.671 1.071c-.6.3-1.243.514-1.971.685-.729.172-1.5.257-2.4.257-1.371 0-2.613-.257-3.727-.728-1.157-.514-2.143-1.156-3-2.013a8.913 8.913 0 01-1.971-2.998 9.366 9.366 0 01-.728-3.64c0-1.286.257-2.528.728-3.684a9.544 9.544 0 011.971-3.042 9.832 9.832 0 013-2.056c1.156-.514 2.399-.77 3.77-.77 1.714 0 3.128.3 4.285.856a9.571 9.571 0 012.956 2.27l-2.571 2.784a9.034 9.034 0 00-2.056-1.627c-.729-.429-1.628-.643-2.657-.643-.771 0-1.457.172-2.099.471-.643.3-1.2.729-1.628 1.242-.472.515-.815 1.157-1.072 1.842a6.476 6.476 0 00-.385 2.27c0 .814.128 1.585.385 2.313a5.306 5.306 0 001.114 1.842 5.518 5.518 0 001.714 1.242c.686.3 1.414.472 2.185.472.986 0 1.843-.215 2.614-.643a10.638 10.638 0 002.1-1.628l2.528 2.485zM139.438 36.057h-4.799l-5.484-7.795-2.528 2.612v5.183h-4.199V11h4.199v14.991l7.541-8.052h5.056l-7.241 7.367 7.455 10.751z"
      />
      <path d="M159.276 27.02a9.741 9.741 0 01-.728 3.683c-.514 1.157-1.157 2.142-2.014 2.998-.857.857-1.885 1.542-3.085 2.056s-2.485.771-3.899.771a10.08 10.08 0 01-3.814-.728 8.788 8.788 0 01-3.042-2.013 9.007 9.007 0 01-2.013-2.998 9.348 9.348 0 01-.729-3.641c0-1.285.257-2.527.729-3.684a9.187 9.187 0 012.013-3.04c.857-.857 1.886-1.543 3.042-2.057a9.693 9.693 0 013.857-.77c1.371 0 2.699.256 3.856.727 1.2.514 2.185 1.157 3.042 2.013a9.01 9.01 0 012.014 2.999 8.872 8.872 0 01.771 3.683zm-4.199.042c0-.814-.128-1.542-.428-2.27a5.34 5.34 0 00-1.157-1.885 5.227 5.227 0 00-1.757-1.242 5.463 5.463 0 00-2.228-.47c-.814 0-1.585.17-2.228.47-.643.3-1.243.729-1.714 1.242a4.966 4.966 0 00-1.071 1.842 6.476 6.476 0 00-.386 2.27c0 .814.129 1.542.429 2.27a5.325 5.325 0 001.157 1.885c.471.557 1.071.943 1.756 1.285a5.513 5.513 0 004.456 0 5.041 5.041 0 001.714-1.242 4.952 4.952 0 001.071-1.842c.258-.728.386-1.499.386-2.313z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.032 36.057h-4.199V17.94h4.199l-.043 2.827c.6-.9 1.328-1.628 2.228-2.27.9-.643 2.057-.943 3.428-.943 2.057 0 3.642.643 4.756 1.885 1.114 1.242 1.671 2.956 1.671 5.097v11.522h-4.199v-10.28c0-1.413-.343-2.527-.985-3.255-.686-.771-1.629-1.156-2.828-1.156-1.2 0-2.186.385-2.914 1.199-.728.77-1.114 1.884-1.114 3.298v10.194z"
      />
    </svg>
  );
};

// Utils
// ------------------------------

function titleCase(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function pageSort(a: any, b: any) {
  let aTitle = a.context.title.toLowerCase();
  let bTitle = b.context.title.toLowerCase();

  // preferential
  let preference = ['getting started', 'welcome', 'introduction'];
  if (preference.includes(aTitle)) {
    return -1;
  }
  if (preference.includes(bTitle)) {
    return 1;
  }

  // standard sort
  if (aTitle < bTitle) {
    return -1;
  }
  if (aTitle > bTitle) {
    return 1;
  }
  return 0;
}

export default AppWrapper;
