/** @jsx jsx */
import { ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { Inline, InlineProps } from '@balance-web/inline';
import { useTheme } from '@balance-web/theme';

export type FooterProps = { children: ReactNode } & Pick<InlineProps, 'gap'>;

export const Footer = ({ children, gap }: FooterProps) => {
  const { borderWidth, palette } = useTheme();
  return (
    <Inline
      alignY="center"
      padding="large"
      css={{
        borderTop: `${borderWidth.standard} solid ${palette.global.border}`,
      }}
      gap={gap}
    >
      {children}
    </Inline>
  );
};
