/** @jsx jsx */

import { ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { forwardRefWithAs } from '@balance-web/utils';

// Only display content to screen readers
// ------------------------------
// See: https://a11yproject.com/posts/how-to-hide-content/

type Props = {
  children?: ReactNode;
};

export const VisuallyHidden = forwardRefWithAs<'span', Props>(
  ({ as: Tag = 'span', ...props }, ref) => {
    return <Tag ref={ref} css={visuallyHiddenStyles} {...props} />;
  }
);

VisuallyHidden.displayName = 'VisuallyHidden';

export const visuallyHiddenStyles = {
  border: 0,
  clip: 'rect(0, 0, 0, 0)',
  height: 1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: 1,
} as const;
