import * as React from 'react';

import { createIcon } from '../Icon';
export const DribbbleIcon = createIcon(
  <React.Fragment>
    <circle cx={12} cy={12} r={10} />
    <path d="M8.56 2.75c4.37 6.03 6.02 9.42 8.03 17.72m2.54-15.38c-3.72 4.35-8.94 5.66-16.88 5.85m19.5 1.9c-3.5-.93-6.63-.82-8.94 0-2.58.92-5.01 2.86-7.44 6.32" />
  </React.Fragment>,
  'DribbbleIcon'
);
