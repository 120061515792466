/** @jsx jsx */

import { ReactNode } from 'react';
import { Flex, FlexProps } from '@balance-web/flex';
import { jsx } from '@balance-web/core';
import { forwardRefWithAs } from '@balance-web/utils';

type SupportedFlexProps = Omit<
  FlexProps,
  'direction' | 'alignItems' | 'justifyContent' | 'inline' | 'wrap'
>;

const alignHorizontalMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
} as const;
const alignVerticalMap = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
} as const;

export type InlineProps = {
  /** Horizontally align items within the container. */
  align?: keyof typeof alignHorizontalMap;
  /** Vertically align items within the container. */
  alignY?: keyof typeof alignVerticalMap;
  /** Each item in the container. */
  children: ReactNode;
} & SupportedFlexProps;

export const Inline = forwardRefWithAs<'div', InlineProps>(
  ({ align = 'left', alignY = 'top', ...flexProps }, forwardedRef) => {
    const justifyContent = alignHorizontalMap[align];
    const alignItems = alignVerticalMap[alignY];

    return (
      <Flex
        alignItems={alignItems}
        justifyContent={justifyContent}
        ref={forwardedRef}
        wrap
        minWidth={0} // fix flex overflow bug that prevents text truncation
        {...flexProps}
      />
    );
  }
);

Inline.displayName = 'Inline';
