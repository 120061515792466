import React, { ReactNode } from 'react';
import { Flex } from '@balance-web/flex';

export const Header = ({ children }: { children: ReactNode }) => {
  return (
    <Flex
      gap="xsmall"
      paddingY="large"
      paddingX="xlarge"
      justifyContent="space-between"
    >
      {children}
    </Flex>
  );
};
