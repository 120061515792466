import { useTheme } from '@balance-web/theme';
import type { BalanceTheme } from '@balance-web/theme';
// TODO: remove "leading" prop

export type UseTextProps = {
  /** The color of the text. */
  color?: keyof BalanceTheme['palette']['text'] | 'inherit';
  /** The leading of the text. */
  leading?: keyof BalanceTheme['typography']['leading'];
  /** The size of the text. */
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  /** The font-weight of the text. */
  weight?: keyof BalanceTheme['typography']['fontWeight'];
};

export function useText({
  color: colorKey = 'base',
  leading = 'base',
  size = 'medium',
  weight = 'regular',
}: UseTextProps) {
  const { palette, typography } = useTheme();
  const resolvedColor =
    colorKey === 'inherit' ? 'inherit' : palette.text[colorKey];

  const styles = {
    color: resolvedColor,
    fontSize: typography.fontSize[size],
    fontWeight: typography.fontWeight[weight],
    lineHeight: typography.leading[leading],
  } as const;

  return styles;
}
