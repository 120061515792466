import React, { ReactNode } from 'react';
import { Box } from '@balance-web/box';

export const Body = ({ children }: { children: ReactNode }) => {
  return (
    <Box paddingY="large" paddingX="medium">
      {children}
    </Box>
  );
};
