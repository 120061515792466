/** @jsx jsx */

import type { CSSProperties, ElementType } from 'react';
import { jsx } from '@balance-web/core';
import type { OverflowStrategyType } from '@balance-web/text';
import { useOverflowStrategy } from '@balance-web/text';
import { useTheme } from '@balance-web/theme';
import { forwardRefWithAs } from '@balance-web/utils';
import type { BalanceTheme } from '@balance-web/theme';

type ValidHeading = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type HeadingLevelType = keyof ReturnType<typeof getLevels>;

type HeadingProps = {
  /** The horizontal alignment. */
  align?: CSSProperties['textAlign'];
  /** The color of the text. */
  color?: keyof BalanceTheme['palette']['heading'] | 'inherit';
  /** The leading of the heading. */
  leading?: keyof BalanceTheme['typography']['leading'];
  /** Manage how text behaves with regard to overflow. */
  overflowStrategy?: OverflowStrategyType;
  /** The heading level. */
  level: HeadingLevelType;
};

export const Heading = forwardRefWithAs<'h1', HeadingProps>(
  (
    {
      as: comp,
      color: colorKey = 'base',
      leading = 'base',
      level,
      overflowStrategy = 'wrap',
      ...props
    },
    ref
  ) => {
    if (level === '1') {
      console.warn(
        'Heading level=1 should not be used before the brand refresh release. All the heading levels have moved down by 1, so the old heading level 1 is now level 2 etc.'
      );
    }

    const theme = useTheme();
    const overflowStyles = useOverflowStrategy(overflowStrategy);

    const levels = getLevels(theme);
    const tagLevel = Number(level) > 6 ? '6' : level;
    const lvl: ValidHeading = `h${tagLevel}` as any;
    const Tag = comp ? comp : lvl;
    const ariaAttributes = getAriaAttributes(comp, level);

    const resolvedColor =
      colorKey === 'inherit' ? 'inherit' : theme.palette.heading[colorKey];

    const styles = {
      color: resolvedColor,
      fontFamily: theme.typography.fontFamily.heading,
      fontSize: levels[level].size,
      fontWeight: levels[level].weight,
      lineHeight: theme.typography.leading[leading],
      textTransform: levels[level].transform,
      ...overflowStyles,
    };

    return (
      <Tag
        ref={ref}
        // @ts-ignore
        css={styles}
        {...ariaAttributes}
        {...props}
      />
    );
  }
);

// Utils
// ------------------------------

/**
 * Prefer native heading tags, but provide accessible attributes if the consumer
 * swaps out the underlying element.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/heading_role
 */

function getAriaAttributes(
  tag: ElementType<any> | undefined,
  level: HeadingLevelType
) {
  if (!tag) {
    return null;
  }

  return { role: 'heading', 'aria-level': level };
}

const getLevels = (theme: BalanceTheme) => {
  const { fontSize, fontWeight } = theme.typography;

  return {
    '1': {
      size: fontSize['xxxxxxlarge'],
      weight: fontWeight.heavy,
      transform: 'none',
    },
    '2': {
      size: fontSize['xxxxlarge'],
      weight: fontWeight.heavy,
      transform: 'none',
    },
    '3': {
      size: fontSize.xxlarge,
      weight: fontWeight.bold,
      transform: 'none',
    },
    '4': {
      size: fontSize.xlarge,
      weight: fontWeight.bold,
      transform: 'none',
    },
    '5': {
      size: fontSize.large,
      weight: fontWeight.bold,
      transform: 'none',
    },
    '6': {
      size: fontSize.medium,
      weight: fontWeight.bold,
      transform: 'none',
    },
    '7': {
      size: fontSize.small,
      weight: fontWeight.bold,
      transform: 'uppercase',
    },
  };
};
