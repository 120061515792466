import type { ReactNode } from 'react';
import React, { useContext } from 'react';
import { Transition } from 'react-transition-group';

import type { TransitionState } from './types';

type DrawerControllerProps = {
  isOpen?: boolean;
  children: ReactNode;
};

const DrawerControllerContext = React.createContext<null | TransitionState>(
  null
);

export const DrawerControllerContextProvider = DrawerControllerContext.Provider;

export const useDrawerControllerContext = () => {
  let context = useContext(DrawerControllerContext);
  if (!context) {
    throw new Error(
      'Drawers must be wrapped in a <DrawerController>. You should generally do this outside of the component that renders the <Drawer>. See https://reckon-web-design-system.netlify.app/package/drawer#drawercontroller for how to use it and why.'
    );
  }

  return context;
};

/** @deprecated The Drawer package has been deprecated. Use the SideDrawer package instead. */
export const DrawerController = ({
  isOpen,
  children,
}: DrawerControllerProps) => {
  return (
    <Transition appear mountOnEnter unmountOnExit in={isOpen} timeout={150}>
      {(transitionState: TransitionState) => {
        return (
          <DrawerControllerContextProvider value={transitionState}>
            {children}
          </DrawerControllerContextProvider>
        );
      }}
    </Transition>
  );
};
