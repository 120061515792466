import { useEffect } from 'react';

type HookOptions = {
  listenWhen: boolean;
};

export function useScrollLock({ listenWhen }: HookOptions) {
  useEffect(() => {
    if (listenWhen && typeof document !== 'undefined') {
      document.body.style.overflow = 'hidden';

      document.body.style.paddingRight = getPadding();

      return () => {
        document.body.style.overflow = '';

        document.body.style.paddingRight = '';
      };
    }
  }, [listenWhen]);
}

// Utils

// ------------------------------

function getPadding() {
  if (typeof document === 'undefined') return `0px`;

  const paddingRight = parseInt(
    window.getComputedStyle(document.body).paddingRight,

    10
  );

  const scrollbarWidth =
    window.innerWidth - document.documentElement.clientWidth;

  return `${paddingRight + scrollbarWidth}`;
}
