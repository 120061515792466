import { hexToTriplet } from './hexToRgb';

/*
From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast

Color brightness is determined by the following formula: 
((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000

NOTE: this approach of brightness VS luminosity is more forgiving of white text than WCAG 3:1 ratio
*/

export function getContrastText(color: string) {
  const [r, g, b] = hexToTriplet(color);

  // Roughly half of 256: lower threshold equals more dark text
  // Less accessible, but more visually appealing for colours that are "close" to the threshold
  const threshold = 136;

  // calculate contrast against grayscale
  var contrast =
    (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;

  return contrast > threshold ? 'black' : 'white';
}
