/** @jsx jsx */

import type { HTMLAttributes, ReactElement, ReactNode, Ref } from 'react';
import { Fragment, forwardRef, useEffect, useState } from 'react';
import type { Options, Placement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import { jsx } from '@balance-web/core';
import { Portal } from '@balance-web/portal';
import { useTheme } from '@balance-web/theme';
import { useClickOutside, useKeyPress } from '@balance-web/utils';
import { Elevate } from '@balance-web/elevate';

type AnchorElementType =
  | HTMLAnchorElement
  | HTMLButtonElement
  | HTMLDivElement
  | HTMLSpanElement;

// Hooks
// ------------------------------

// Generic Hook
type PopoverOptions = {
  closeWhen: ('clickoutside' | 'escapepress')[];
  onClose?: () => void;
};

export const usePopover = (
  popperOptions: Partial<Options> = {},
  popoverOptions: PopoverOptions = {
    closeWhen: ['clickoutside', 'escapepress'],
  }
) => {
  const [anchorElement, setAnchorElement] = useState<AnchorElementType | null>(
    null
  );
  const [popoverElement, setPopoverElement] = useState<HTMLDivElement>();
  const [arrowElement, setArrowElement] = useState<HTMLDivElement>();
  const [isOpen, setOpen] = useState(false);

  const { styles, attributes, update } = usePopper(
    anchorElement,
    popoverElement,
    {
      ...popperOptions,
      modifiers: [
        ...(popperOptions.modifiers || []),
        { name: 'arrow', options: { element: arrowElement } },
      ],
    }
  );

  // update popper when it opens to get the latest placement
  // useful for prerendered popovers in modals etc.
  useEffect(() => {
    if (update && isOpen) {
      update();
    }
  }, [isOpen, update]);

  // close on click outside
  useClickOutside({
    handler: () => {
      if (popoverOptions.onClose) {
        popoverOptions.onClose();
        return;
      }
      setOpen(false);
    },
    elements: [anchorElement, popoverElement],
    listenWhen: popoverOptions.closeWhen.includes('clickoutside') && isOpen,
  });

  // close on esc press
  useKeyPress({
    targetKey: 'Escape',
    targetElement: popoverElement,
    downHandler: (event: KeyboardEvent) => {
      event.preventDefault(); // Avoid potential drawer close
      if (popoverOptions.onClose) {
        popoverOptions.onClose();
        return;
      }
      setOpen(false);
    },
    listenWhen: popoverOptions.closeWhen.includes('escapepress') && isOpen,
  });

  return {
    isOpen,
    setOpen,
    trigger: {
      ref: (element: AnchorElementType | null) => {
        return setAnchorElement(element);
      },
      props: {
        'aria-haspopup': true,
        'aria-expanded': isOpen,
      },
    },
    dialog: {
      ref: (element: HTMLDivElement) => {
        return setPopoverElement(element);
      },
      props: {
        style: styles.popper,
        ...attributes.popper,
      },
    },
    arrow: {
      ref: (element: HTMLDivElement) => {
        return setArrowElement(element);
      },
      props: {
        style: styles.arrow,
      },
    },
  };
};

// Component
// ------------------------------

export type TriggerRendererOptions = {
  isOpen: boolean;
  triggerProps: {
    onClick: () => void;
    ref: Ref<any>;
  };
};
type Props = {
  /** The content of the dialog. */
  children: ReactNode;
  /** Where, in relation to the trigger, to place the dialog. */
  placement?: Placement;
  /** The trigger element, which the dialog is bound to. */
  triggerRenderer: (options: TriggerRendererOptions) => ReactElement;
};

export const Popover = ({
  placement = 'bottom',
  triggerRenderer,
  ...props
}: Props) => {
  const { isOpen, setOpen, trigger, dialog } = usePopover({
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <Fragment>
      {triggerRenderer({
        isOpen,
        triggerProps: {
          ref: trigger.ref,
          ...trigger.props,
          onClick: () => {
            return setOpen(true);
          },
        },
      })}
      <PopoverDialog
        isVisible={isOpen}
        ref={dialog.ref}
        {...dialog.props}
        {...props}
      />
    </Fragment>
  );
};

// Dialog
// ------------------------------

type DialogProps = {
  /** The content of the dialog. */
  children: ReactNode;
  /** When true, the popover will be visible. */
  isVisible: boolean;
  /**
   * Influencing the border-radius of the dialog, the "size" property should
   * match that of the invoking element.
   * @default 'medium'
   */
  size?: 'small' | 'medium';
} & HTMLAttributes<HTMLDivElement>;

export const PopoverDialog = forwardRef<HTMLDivElement, DialogProps>(
  ({ isVisible, size = 'medium', children, ...props }, consumerRef) => {
    const { elevation, palette, radii, shadow } = useTheme();
    const borderRadius = radii[size];

    return (
      <Portal>
        <div
          ref={consumerRef}
          css={{
            background: palette.background.dialog,
            borderRadius,
            boxShadow: shadow.medium,
            opacity: isVisible ? 1 : 0,
            overflow: 'hidden',
            pointerEvents: isVisible ? undefined : 'none',
            zIndex: elevation.popover, // on top of drawers
          }}
          {...props}
        >
          <Elevate>{children}</Elevate>
        </div>
      </Portal>
    );
  }
);

PopoverDialog.displayName = 'PopoverDialog';
