import type { BalanceTheme } from './types';
import type { DeepPartial } from './utils';
import { mergeDeep } from './utils';

// Theme
// ------------------------------

export type BalanceThemeWithPalette = BalanceTheme;

// deep merge helper to maintain theme types
export function makeTheme(
  source: BalanceTheme,
  partial: DeepPartial<BalanceTheme>
) {
  return mergeDeep(source, partial);
}
