const strategyMap = {
  wrap: null,
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  // https://css-tricks.com/better-line-breaks-for-long-urls/
  breakword: {
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
  },
} as const;

export type OverflowStrategyType = keyof typeof strategyMap;

export function useOverflowStrategy(
  strategy: OverflowStrategyType,
  numberOfLines?: number
) {
  if (numberOfLines) {
    return {
      display: '-webkit-box',
      WebkitLineClamp: numberOfLines,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    } as const;
  }

  return strategyMap[strategy];
}
