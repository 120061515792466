import { useContext } from 'react';

import { MaxElevationContext } from './MaxElevationProvider';

const useMaxElevationContext = () => {
  const context = useContext(MaxElevationContext);

  if (!context)
    throw Error(
      'useElevationContext can only be used inside MaxElevationProvider.'
    );

  return context;
};

export const useOnElevate = () => {
  const { onElevate } = useMaxElevationContext();
  return { onElevate };
};

export const useMaxElevationModifier = () => {
  const { zIndex } = useMaxElevationContext();
  return zIndex;
};
