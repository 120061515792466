import { useTheme } from '@balance-web/theme';
import { CSSObject } from '@balance-web/core';

export const useDefaultInteractiveStyles = () => {
  const { palette } = useTheme();

  const styles: CSSObject = {
    cursor: 'pointer',
    ':hover, :focus, &.focus-visible': {
      background: palette.interactive.base.hover,
    },

    ':active': {
      background: palette.interactive.base.active,
    },
  };

  return styles;
};
