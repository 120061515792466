/** @jsx jsx */

import { jsx, keyframes } from '@balance-web/core';
import { BalanceTheme, useTheme } from '@balance-web/theme';

// NOTE: a more accurate implementation might use `aria-busy="true|false"` on
// the wrapping element, but it's difficult to abstract

type Colors = 'inherit' | 'default' | 'dim';
type Sizes = 'small' | 'medium' | 'large';

type Props = {
  /** The aria-label for screen readers. */
  label: string;
  /** The color of the dots. */
  color?: Colors;
  /** The size of the dots. */
  size?: Sizes;
};

export const LoadingDots = ({
  label,
  color = 'default',
  size = 'medium',
  ...props
}: Props) => {
  const { palette, spacing } = useTheme();
  const colorMap = {
    inherit: 'currentColor',
    default: palette.global.loading,
    dim: palette.text.dim,
  };

  const resolvedSize = getSize(spacing, size);
  const resolvedColor = colorMap[color];

  return (
    <div
      aria-live="polite"
      aria-label={label}
      css={{
        color: resolvedColor,
        display: 'flex',
        fontSize: resolvedSize,
      }}
      {...props}
    >
      <Dot delay={0} />
      <Dot delay={160} />
      <Dot delay={320} />
    </div>
  );
};

// Styled Components
// ------------------------------

const fadeAnim = keyframes({
  '0%, 80%, 100%': { opacity: 0 },
  '40%': { opacity: 1 },
});

const Dot = ({ delay }: { delay: number }) => {
  return (
    <div
      css={{
        animation: `${fadeAnim} 1s ease-in-out infinite`,
        animationDelay: `${delay}ms`,
        backgroundColor: 'currentColor',
        borderRadius: '50%',
        display: 'block',
        height: '1em',
        width: '1em',

        '&:not(:first-of-type)': {
          marginLeft: '1em',
        },
      }}
    />
  );
};

// Utils
// ------------------------------

function getSize(spacing: BalanceTheme['spacing'], key: Sizes) {
  const map = {
    small: spacing.xsmall,
    medium: spacing.small,
    large: spacing.medium,
  };

  return map[key];
}
