import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useScrollLock } from '@balance-web/utils';

function notInContextFn() {
  throw Error(
    'This component must have a <SideDrawerProvider/> ancestor in the same React tree.'
  );
}

const defaultState = {
  sideDrawerStack: [] as string[],
  pushToDrawerStack: notInContextFn as (drawerKey: string) => void,
  popFromDrawerStack: notInContextFn,
};
export type SideDrawerState = typeof defaultState;

const SideDrawerContext = React.createContext<SideDrawerState>(defaultState);

export const SideDrawerProvider = ({ children }: { children: ReactNode }) => {
  let [sideDrawerStack, setSideDrawerStack] = useState<string[]>([]);

  const pushToDrawerStack = useCallback((key: string) => {
    setSideDrawerStack((stack) => [...stack, key]);
  }, []);
  const popFromDrawerStack = useCallback(() => {
    setSideDrawerStack((stack) => {
      let less = stack.slice(0, -1);
      return less;
    });
  }, []);

  useScrollLock({ listenWhen: Boolean(sideDrawerStack.length) });

  const context = {
    sideDrawerStack,
    pushToDrawerStack,
    popFromDrawerStack,
  };

  return (
    <SideDrawerContext.Provider value={context}>
      {children}
    </SideDrawerContext.Provider>
  );
};

// Utils
// ------------------------------
export const useSideDrawerManager = (uniqueKey: string) => {
  let sideDrawerState = React.useContext(SideDrawerContext);

  // keep the stack in sync on mount/unmount
  useEffect(() => {
    sideDrawerState.pushToDrawerStack(uniqueKey);
    return () => {
      sideDrawerState.popFromDrawerStack();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // the last key in the array is the "top" drawer visually, so the depth is the inverse index
  // be careful not to mutate the stack
  let depth = sideDrawerState.sideDrawerStack
    .slice()
    .reverse()
    .indexOf(uniqueKey);
  // if it's not in the stack already,
  // we know that it should be the last drawer in the stack but the effect hasn't happened yet
  // so we need to make the depth 0 so the depth is correct even though the effect hasn't happened yet
  return depth === -1 ? 0 : depth;
};

export type SideDrawerBaseContextType = {
  headingId?: string;
};

export const SideDrawerBaseContext = createContext<SideDrawerBaseContextType | null>(
  null
);

export const useSideDrawerBase = () => {
  const context = useContext(SideDrawerBaseContext);

  if (!context) throw Error('useSideDrawerBase must be used inside SideDrawer');

  return context;
};
