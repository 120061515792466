/**
 * This is a simple, *insecure* hash that's short, fast, and has no
 * dependencies. Loosely based on Java’s String.hashCode() method.
 * @see https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 * @param str input string
 * @returns hash code
 */
export function simpleHash(str: string) {
  let hash = 0;

  if (str.length === 0) return hash;

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }

  return hash >>> 0;
}
